import { RenderLazy, default as Render } from '@lib/shared/render';
import { bounceBack, control_history, animateCSS, httpBuildQuery, handlePromiseErr } from '@lib/shared/util';
import Globals from '@lib/globals';
import { handle_rest_rejections, rest_entry_point } from '@lib/shared/ajax';
import '@node/bootstrap/js/dist/collapse';
import '@node/bootstrap/js/dist/tooltip';
import loadModule from '@lib/shared/loadModule';

export default class {
  constructor(page) {
    this.page = page;
    this.init();
  }
  async http() {
    await this.beforeRender();
    let config = {
      template: this.getTemplate(),
      context: this.getEndpoint(),
      node: this.getSelector(),
      styles: this.getStyles(),
      append: false,
      observerOptions: this.getObserverOptions(),
      chunkSort: this.getPageableChunksSortField(),
      fn_data_transform: this.getTransformer()
    };
    let context = await Render(config);
    return this.afterRender(context);
  }
  async navigate(url) {
    try {
      this.href = url;
      this.checkAuth();
      bounceBack(0);
      await this.updatePageMeta();
      await this.http();
    } catch (e) {
      handle_rest_rejections(e);
    }
  }
  checkAuth() {
    // Create new REST or reuse AJAX entry to gain or check access
    // return new token? true/false? Handle the redirect??
  }
  getSelector() {
    return $('#fg-delegate');
  }
  init() {
    // super class default do nothing
  }
  getObserverOptions() {
    return null;
  }
  getTransformer() {
    return null;
  }
  getPageableChunksSortField() {
    return null;
  }
  getEndpoint() {
    return null;
  }
  getPageTitle() {
    // implemented in subclasses
  }
  getTemplate() {
    return this.template;
  }
  getStyles() {
    return this.styles;
  }
  beforeRender() {
    this.updateHistory();
    $('body.party-orig').addClass('progress'); // Only party-orig otherwise every navigation will flash
    // Must return a resolved Promise, this is the default behavior, extend in subclass if necessary
    return Promise.resolve('parent beforeRender()');
  }
  async afterRender() {
    this.loadVendors();

    if (this.page.match(/(party-orig|launch|attendee-master)/)) {
      try {
        const dzModule = await import(/* webpackChunkName: "dropzone-global" */ 'dropzone');
        window.Dropzone = dzModule.default;
        Dropzone.autoDiscover = false;
        Dropzone.options.uploadImages = false;
        const localDropzone = await import(/* webpackChunkName: "dropzone-local" */ '@lib/shared/dropzone');
        localDropzone.default();
      } catch (e) {
        handlePromiseErr(e);
      }
    }

    return new Promise((resolve, reject) => {
      if ($('#global-progress').length === 0) {
        $('body').removeClass('progress');
        resolve('parent afterRender()');
        return;
      }
      $('#global-progress').animate(
        {
          opacity: 0
        },
        900,
        () => {
          $('body').removeClass('progress'); // Global progress DOM only
          $('#global-progress').css({ opacity: 1 });
          resolve('parent afterRender()');
        }
      );
    }).catch(err => fg_error(err));
  }
  updateHistory() {
    control_history(this.href || window.location.href, 'page', this.page);
  }
  updatePageMeta() {
    let pageTitle = this.getPageTitle();
    $('title').html(`${pageTitle} - WANTGROUP.men`);

    $('body')
      .removeClass(Globals.ROUTES.filter(route => route != this.page).join(' '))
      .toggleClass(this.page, Globals.ROUTES.includes(this.page));

    return rest_entry_point('critical-css/?' + httpBuildQuery({ page: this.page }))
      .then(response => {
        const crit = document.getElementById('critical-css');
        if (crit) {
          crit.innerHTML = response.response.criticalcss;
        }
      })
      .catch(error => {
        fg_error(error);
      });
  }
  async loadVendors() {
    (function() {
      if ($('#fg-delegate').length < 1) return;
      $('#fg-delegate')
        .tooltip({
          container: 'body',
          placement: 'top',
          selector: '[data-toggle="tooltip"]'
        })
        .on('shown.bs.tooltip', function() {
          const tooltipInner = $('.tooltip .tooltip-inner');
          tooltipInner.css({
            border: '1px solid #2e6da4'
          });
          animateCSS(tooltipInner, 'pulse slower');
        });
    })();
    await loadModule('modal');
    $('.modal').modal({ show: false, backdrop: 'static' });
    if (this.page.match(/(reset|home|user-error)/)) {
      this.loadRecaptcha();
    } else {
      this.removeRecaptcha();
    }
  }
  loadRecaptcha() {
    const exists = document.getElementById('recaptcha-script');
    if (exists) {
      return;
    }
    const script = document.createElement('script');

    script.src = `https://www.google.com/recaptcha/api.js?render=${ajax_obj.RECAPTCHA_SITE_KEY}`;
    script.id = 'recaptcha-script';
    script.async = true;

    document.body.append(script);
    fg_console('Recaptcha Loaded');
  }
  removeRecaptcha() {
    const script = document.getElementById('recaptcha-script');
    if (script) {
      script.remove();
    }

    const recaptchaElems = document.getElementsByClassName('grecaptcha-badge');
    if (recaptchaElems.length) {
      recaptchaElems[0].remove();
    }

    const gstatic = [...document.getElementsByTagName('script')].filter(s => s.src.match(/recaptcha/));
    if (gstatic.length) {
      gstatic[0].remove();
    }

    fg_console('Recaptcha Removed');
  }
}
