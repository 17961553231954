import { get_current_user } from '@lib/shared/util';

const GA = {
  dispatch: ( task, ...args ) => {
    var taskobj = GA[task];
    if ( taskobj instanceof Function ) {
      taskobj = GA[task](args);
    }
    let event = { event_name: task };
    return { ...taskobj, ...event };
	},
  collapseInvite: {
    page_virtual: '/invite-request-scrolled',
    event_category: 'Enroll',
    event_action: 'click',
    event_label: 'collapseInvite'
  },
  inviteTyping: (field_value) => {
    return {
      page_virtual: '/invite-request-typing',
      event_category: 'Enroll',
      event_action: 'typing',
      event_label: 'field: ' + field_value[0] + ' | value: ' + field_value[1],
      enroll_field: field_value[0],
      enroll_value: field_value[1]
    };
  },
  inviteSubmit: {
    page_virtual: '/invite-request-submit',
    event_category: 'Enroll',
    event_action: 'submit',
    event_label: 'invite_request'
  },
  inviteFailed: email => {
    return {
      page_virtual: '/invite-request-failed',
      event_category: 'Enroll',
      event_action: 'submit',
      event_label: 'invite_request_failed: ' + email[0]
    };
  },
  inviteDig: id => {
    return {
      page_virtual: '/invite-request-dig',
      event_category: 'Enroll',
      event_action: 'click',
      event_label: id[0]
    };
  },
  menuClicked: post_name => {
    return {
      event_category: 'Menu',
      event_action: 'click',
      event_label: '/party-orig/#' + post_name[0]
    };
  },
  quickLook: (post_name) => {
    return {
      event_category: 'quicklook',
      event_action: 'click',
      event_label: '/attendee/' + post_name[0],
      a_id_viewed: post_name[1]
    };
  },
  userAccess: ( user_cat_status ) => {
    return {
      event_category: user_cat_status[1],
      event_action: 'access: ' + user_cat_status[2],
      event_label: user_cat_status[0]
    };
  },
  trackEnlarge: image => {
    return {
      event_category: 'Enlarge',
      event_action: 'click',
      event_label: image[0],
      a_id_viewed: image[1]
    };
  },
  clickFilter: ( cat_filterFor ) => {
    return {
      event_category: cat_filterFor[0],
      event_action: 'click',
      event_label: cat_filterFor[1]
    };
  },
  smsOptIn: (action_label) => {
    return {
      event_category: 'SMS Opt-in',
      event_action: action_label[0],
      event_label: action_label[1]
    };
  }
};

export default GA;
