import { rest_entry_point } from '@lib/shared/ajax';
import { getModuleName } from '@lib/shared/util';
import Globals from '@lib/globals';
import sortObjects from '@template/helpers/sortObjects';
import loadModule from '@lib/shared/loadModule';

/**
 * Helper function to dispatch pre-compiled handlebars template with content
 * Use this function for initial page-load rendering
 * The styles correspond with the template of the same basename
 * @see RenderLazy for invoking templates at runtime
 *
 * @param  {Function} template      a reference to the template function, pre-imported before passing here
 * @param  {Object|Array|string} context  either an object of data or rest route endpoint or Array of endpoints
 * @param  {HtmlElement} node       parent node to html() or append()
 * @param  {Module} styles          the imported styles module
 * @param  {boolean} append         append or replace, defaults to false/replace
 * @return {Promise}                with the parent node as filled
 *
 * Typical Usage:
 *
 * import Render        from '@lib/shared/render';
 * import publicForm from '@template/publicForm.handlebars';
 * import styles        from '@scss/template/_publicForm.module.scss';
 *   ...
 * Render(publicForm, 'party', $('.publicForm'), styles, true).then(node => {
 *  console.log(node);
 * });
 *
 */
export default function(config) {
  let { context, styles } = config;
  return new Promise((resolve, reject) => {
    var render = new _Render(config);
    styles = styles.default || styles;
    Globals.STYLES = { ...Globals.STYLES, ...styles };
    (async () => {
      await render._setContext(context || {});
      let resolved_context = await render._render(Globals.STYLES);
      resolve(resolved_context);
    })();
  }).catch(error => {
    fg_error(error);
  });
}

/**
 * Define the Render engine, private
 */
function _Render(config) {
  let { template, node, append, observerOptions, fn_data_transform, chunkSort } = config;
  if (!template instanceof Function) {
    throw new Error('Template must be a reference to a compiled template function');
  }
  this.template = template;
  this.node = $(node).get(0);
  this.append = append || false;
  this.observerOptions = observerOptions;
  this.chunkSort = chunkSort;
  this.fn_data_transform = fn_data_transform;
}

_Render.prototype = {
  // Must set context before rendering
  _setContext: async function(context, callback) {
    if (context instanceof Array) {
      const contextTree = {};
      await Promise.all(
        context.map(async (endpoint, index) => {
          let data = null;
          var context_id = endpoint;
          if (typeof endpoint === 'string') {
            data = await rest_entry_point(endpoint);
          } else if (Array.isArray(endpoint)) {
            var chunk_data = [],
              namespace = null;
            // Means batch of single endpoint
            await Promise.all(
              endpoint.map(async endpoint_last_seen => {
                let batched_result = await rest_entry_point(endpoint_last_seen),
                  data_arr = Object.values(batched_result.response)[0];
                data_arr.map(val => chunk_data.push(val));
                namespace = namespace || Object.keys(batched_result.response)[0] || null;
              })
            );
            // Sort the chunk_data
            if (this.chunkSort) {
              chunk_data = sortObjects(chunk_data, this.chunkSort, false);
            }
            let final_endpoint_data = {};
            final_endpoint_data[namespace] = chunk_data;
            data = { response: final_endpoint_data };
          } else if (typeof endpoint === 'object') {
            // pass a raw object lets you combine context types passed in
            context_id = 'inline_' + index;
            data = await (() => Promise.resolve(endpoint))();
          }
          contextTree[context_id] = data.response;
        })
      );
      this.context = mergeContexts(contextTree);
    } else if (context instanceof Object) {
      this.context = context;
    } else {
      const data = await rest_entry_point(context);
      this.context = data.response;
    }

    // Transform or filter the data if necessary
    if (this.fn_data_transform && this.fn_data_transform instanceof Function) {
      // Note styles are not passed in yet
      this.context = this.fn_data_transform(this.context);
    }

    return Promise.resolve('context loaded');
  },
  // Returns a promise after rendering
  _render: function(styles) {
    const thiz = this;

    return new Promise((resolve, reject) => {
      const template_args = { response: thiz.context, styles: styles };
      const df = document.createRange().createContextualFragment(thiz.template(template_args));

      if (thiz.observerOptions) {
        waitForAddedNode(thiz.observerOptions, () => {
          resolve(template_args);
        });
      } else {
        resolve(template_args);
      }

      if (thiz.append) {
        $(thiz.node).append(df.cloneNode(true));
      } else {
        $(thiz.node)
          .empty()
          .html(df.cloneNode(true));
      }
    }).catch(err => {
      fg_error(err);
      return Promise.reject(err);
    });
  }
};

/**
 * Helper function to dispatch LAZY handlebars template with content
 * The styles correspond with the template of the same basename
 *
 * @param  {string} templateName    handlebars file relative the the @template directory
 * @param  {Object|string} context  either an object of data or rest route endpoint
 * @param  {HtmlElement} node       parent node to html() or append()
 * @param  {boolean} append         append or replace, defaults to false/replace
 * @param  {function} fn_data_transform  transform post-render
 * @param  {string} chunkSort       the field by which to sort, only when running in parallel chunks
 * @return {Promise}                with the parent node as filled
 *
 * Typical Usage: ( notice the style is not passed because it's bound by the same name )
 *
 * import { RenderLazy } from '@lib/shared/render';
 *   ...
 * RenderLazy( 'publicForm', 'party', $('.publicForm'), true ).then(node => {
 *   console.log(node);
 * });
 *
 */

export async function RenderLazy(config) {
  let { templatePath, context, node, append, fn_data_transform, chunkSort } = config;

  var data = {};
  context = context || {};

  if (Array.isArray(context)) {
    const contextTree = {};
    // The waits for all the promises to resolve, a great trick !!
    // Should probably use it elsewhere where you're counting indexes
    await Promise.all(
      context.map(async (endpoint, index) => {
        var localData = null;
        var context_id = endpoint;
        if (typeof endpoint === 'string') {
          localData = await rest_entry_point(endpoint);
        } else if (Array.isArray(endpoint)) {
          var chunk_data = [],
            namespace = null;
          // Means batch of single endpoint
          await Promise.all(
            endpoint.map(async endpoint_last_seen => {
              let batched_result = await rest_entry_point(endpoint_last_seen),
                data_arr = Object.values(batched_result.response)[0];
              data_arr.map(val => chunk_data.push(val));
              namespace = namespace || Object.keys(batched_result.response)[0] || null;
            })
          );
          // Sort the chunk_data
          if (chunkSort) {
            chunk_data = sortObjects(chunk_data, chunkSort, false);
          }
          let final_endpoint_data = {};
          final_endpoint_data[namespace] = chunk_data;
          localData = { response: final_endpoint_data };
        } else if (typeof endpoint === 'object') {
          // pass a raw object lets you combine context types passed in
          context_id = 'inline_' + index;
          localData = await (() => Promise.resolve(endpoint))();
        }
        contextTree[context_id] = localData.response;
      })
    );
    data = { response: mergeContexts(contextTree) };
  } else if (context instanceof Object) {
    data = { response: context };
  } else {
    data = await rest_entry_point(context);
  }

  // Transform or filter the data if necessary
  if (fn_data_transform && fn_data_transform instanceof Function) {
    // Note styles are not passed in yet
    data = fn_data_transform(data);
  }

  let page = getModuleName();
  let styles = {};
  try {
    styles = await loadModule('style', page);
  } catch (err) {
    // Do nothing, means there's no associated styles
    fg_console(err);
  }

  templatePath = templatePath.replace(/\.handlebars$/, '');

  return loadModule('template', templatePath)
    .then(module => {
      const templateFunc = module.default;
      fg_console('loaded ' + templatePath);
      styles = styles.default || styles;
      Globals.STYLES = { ...Globals.STYLES, ...styles };

      // Apply context to the template (handlebars)
      const template_args = { response: data.response, styles: Globals.STYLES };
      const documentFrag = document.createRange().createContextualFragment(templateFunc(template_args));

      return {
        context: template_args,
        documentFrag: documentFrag
      };
    })
    .then(data => {
      if (!data.documentFrag.children.length) {
        throw new Error(rest_obj.response_codes.REST_NO_DATA);
      }

      const prom = (resolve, reject) => {
        waitForAddedNode(
          {
            id: $(node).attr('id'),
            recursive: false
          },
          () => {
            resolve(data.context);
          }
        );
        if (append) {
          $(node).append(data.documentFrag.cloneNode(true));
        } else {
          $(node).html(data.documentFrag.cloneNode(true));
        }
      };

      return new Promise(prom);
    });
}

function mergeContexts(contextTree) {
  let keys = Object.keys(contextTree),
    merged = {};
  keys.forEach(endpoint => {
    merged = { ...merged, ...contextTree[endpoint] };
  });
  return merged;
}

function waitForAddedNode(params, callback) {
  new MutationObserver(function(mutations) {
    var el = document.getElementById(params.id);
    if (el) {
      this.disconnect();
      callback(el);
    }
  }).observe(params.parent || document, {
    subtree: !!params.recursive || !params.parent,
    childList: true
  });
}
