import { handlePromiseErr } from '@lib/shared/util';

let moduleCache = {};

/**
 *   Retrieves via lazy load or from cache
 *   @param  {string} module_name must be sent absolute path
 *   @return {Object} The module promise
 */
export default function(path_alias, module_name) {
  if (module_name) module_name = module_name.replace(/\.js$/, '');
  let key = module_name || path_alias;
  return new Promise((resolve, reject) => {
    if (!moduleCache[key]) {
      let imported;
      switch (path_alias) {
        case 'lib':
          imported = import(/* webpackChunkName: "[request]" */ `@lib/${module_name}`);
          break;
        case 'route':
          imported = import(/* webpackChunkName: "[request]" */ `@src/routes/${module_name}`);
          break;
        case 'style':
          imported = import(/* webpackChunkName: "[request]" */ `@scss/template/_${module_name}.module.scss`);
          break;
        case 'template':
          imported = import(/* webpackChunkName: "[request]" */ `@template/${module_name}.handlebars`);
          break;
        case 'clipboard':
          imported = import(/* webpackChunkName: "modal" */ 'clipboard');
          break;
        case 'modal':
          imported = import(/* webpackChunkName: "modal" */ '@node/bootstrap/js/dist/modal');
          break;
        default:
      }
      imported
        .then(module => {
          moduleCache[key] = module;
          fg_console(key, 'lazy loaded and added to cache');
          resolve(module);
        })
        .catch(error => {
          if (error.message == rest_obj.response_codes.REST_NO_DATA) {
            throw new Error(error.message);
          }
          handlePromiseErr( error );
        });
      return;
    }
    fg_console(key, 'loaded from cache');
    resolve(moduleCache[key]);
  });
}
