import Globals from '@lib/globals';
import { clearTimers } from '@lib/shared/util';
import loadModule from '@lib/shared/loadModule';

export default class {
  constructor(href, page) {
    if (!href || !page) {
      throw new Error('Missing href and or page template');
    }
    this.href = href;
    this.page = page;
  }

  go(hardReload) {
    hardReload = hardReload || false;
    const router = Globals.ROUTER,
      route = this.page;
    if (route && router.routes.includes(route)) {
      (async () => {
        await clearTimers();
        if (hardReload) {
          return window.hardReload();
        }
        const loaded_route = await loadModule('route', route);
        const pageObj = new loaded_route.default(route);
        pageObj.navigate(this.href);
        fg_console('loaded ' + this.page);
      })();
    }
  }
}
