import { user_profile_page_init, user_endpoint_init, party_menu_init, update_view_stats } from '@lib/shared/state';
import {
  rest_entry_point,
  reload_rsvp_counts,
  load_profile_images,
  load_grid,
  load_note_history,
  reload_party_meta,
  reload_attendee_essentials,
  get_pageable_chunks
} from '@lib/shared/ajax';
import { original_grid, update_elements_after_filter, update_rsvp_count } from '@lib/shared/filtering';
import {
  get_party_meta,
  fix_lowres_images,
  get_selected_r_id,
  observeIntersection,
  preload_enlargeable_images,
  collapse_scroll,
  control_history,
  get_selected_party,
  fg_alert,
  get_rsvp_count,
  get_current_user,
  httpBuildQuery
} from '@lib/shared/util';
import Globals from '@lib/globals';
import Render from '@lib/shared/render';
import { RenderLazy } from '@lib/shared/render';
import Route from '@lib/Route';
import Poll from '@lib/Poll';
import template from '@template/party-orig/party-orig.handlebars';
import styles from '@scss/template/_party-orig.module.scss';

export default class extends Route {
  constructor(page) {
    super(page);
  }
  async init() {
    this.template = template;
    this.styles = styles;

    let args = {
      table: 'vw_all_affirmative_rsvps_for_all_parties',
      p_id: get_selected_party()
    };

    // Sets up the big query batches, set the global
    this.pageableChunks = await get_pageable_chunks(args);

    fg_console('party-orig');
  }
  async beforeRender() {
    const super_parent = async () => {
        return await Route.prototype.beforeRender.call(this); // fancy super(), which is not allowed outside constructor
      },
      preloads = [super_parent, reload_attendee_essentials, reload_party_meta, reload_rsvp_counts];

    return await Promise.all(
      preloads.map(async func => {
        await func();
      })
    ).then(() => {
      this.getSelector().empty();
      fg_console('beforeRender() complete');
    });
  }
  async afterRender(context) {
    await Route.prototype.afterRender.call(this); // fancy super(), which is not allowed outside constructor
    public_init(context);
    fg_console('afterRender() complete');
  }
  getTransformer() {
    return segregate_waitlisters;
  }
  getPageableChunksSortField() {
    return 'modified';
  }
  getObserverOptions() {
    return {
      id: 'fg-main-grid',
      parent: document.querySelector('._on-ice-wrapper'),
      recursive: false
    };
  }
  getPageTitle() {
    return 'Party';
  }
  getEndpoint() {
    try {
      const endpoints = [
        'ui-party-orig/?' +
          httpBuildQuery({
            p_id: get_selected_party()
          }),
        'ui-crud-user-profile/?' +
          httpBuildQuery({
            namespace: 'user_profile',
            a_id: get_current_user()
          }),
        'ui-crud-user-rsvp/?' +
          httpBuildQuery({
            namespace: 'user_rsvp',
            a_id: get_current_user(),
            p_id: get_selected_party()
          }),
        'ui-note-history/?' +
          httpBuildQuery({
            a_id: get_current_user(),
            p_id: get_selected_party(),
            namespace: 'rsvp_notes'
          }),
        'ui-misc/?' +
          httpBuildQuery({
            namespace: 'filter_tag_buttons'
          }),
        'ui-select-party/?' +
          httpBuildQuery({
            a_id: get_current_user(),
            p_id: get_selected_party(),
            namespace: 'active_parties'
          }),
        'ui-picture-engine/?' +
          httpBuildQuery({
            a_id: get_current_user(),
            namespace: 'image_management',
            image_size: 'small-full',
            filter_image_status: [
              Globals.IMAGE_STATUS.approved.int,
              Globals.IMAGE_STATUS.review.int,
              Globals.IMAGE_STATUS.hidden.int
            ]
          })
      ];

      const partyStatus = get_party_meta(get_selected_party(), 'is_active');

      if (
        partyStatus == Globals.PARTY_STATUS.active.int ||
        partyStatus == Globals.PARTY_STATUS.partyover.int ||
        (partyStatus == Globals.PARTY_STATUS.firstclass.int && Globals.ATTENDEE.rating > 3)
      ) {
        let thiz = this;
        endpoints.unshift(
          this.pageableChunks.chunks.map(
            last_seen_id =>
              'ui-public-grid/?' +
              httpBuildQuery({
                p_id: get_selected_party(),
                namespace: 'public_grid',
                image_size: 'small-full',
                last_seen_id: last_seen_id,
                batch_size: thiz.pageableChunks.limit
              })
          )
        );
      } else {
        endpoints.push(
          'ui-picture-engine/?' +
            httpBuildQuery({
              namespace: 'tiny_image_grid',
              exclude_faces: true,
              image_size: 'small',
              filter_image_status: [Globals.IMAGE_STATUS.approved.int],
              random_limit: 100
            })
        );
      }

      return endpoints;
    } catch (e) {
      // Redirect to user-error, something went wrong
      //const nav = new Navigator()
      throw new Error(e);
    }
  }
}

function public_init(context) {
  // sync up selected party
  //
  // DELETEME I am skeptical of why this is necessary
  /*
  var party_toggle = $('#fg-delegate').attr('party-toggle');
  if (party_toggle) {
    $('#_selectedParty').attr('data-selected-party', party_toggle);
    $('#fg-delegate').attr('party-toggle', '');
  }
  */

  // Remove progress on the grid
  $('#fg-main-grid')
    .removeClass(Globals.STYLES.mainGridProgress)
    .addClass(Globals.STYLES.mainGrid);

  fix_lowres_images();

  preload_enlargeable_images($('._enlarge-parent'));

  party_menu_init();

  Globals.PUBLIC_IMAGE_DATA = context.response.public_grid;

  const heartbeatPoll = new Poll({
    fn: () => {
      return rest_entry_point(
        'heartbeat/?' +
          httpBuildQuery({
            a_id: get_current_user(),
            p_id: get_selected_party()
          })
      );
    },
    callback: async response => {
      var data = response.response.data,
        approvals = data.recent_image_approvals,
        rsvp_note_unread = data.rsvp_note_unread,
        reply_n = data.reply_n;
      if (approvals) {
        await load_profile_images();
        user_endpoint_init();
        fg_alert('One or more of your image(s) has been approved');
        const tid = setTimeout(() => {
          load_grid(true);
        }, 2000);
        Globals.TIMERS.push(tid);
      }

      if (rsvp_note_unread) {
        await load_note_history(true);
      }

      if (reply_n !== undefined) {
        var possibly_stale_reply = parseInt($('input[name="wpcf-rsvp-reply"]:checked').val());
        if (possibly_stale_reply && reply_n !== possibly_stale_reply) {
          let config = {
            templatePath: 'partials/crud-user-rsvp',
            context: [
              'ui-crud-user-rsvp/?' +
                httpBuildQuery({
                  namespace: 'user_rsvp',
                  a_id: get_current_user(),
                  p_id: get_selected_party()
                }),
              'ui-note-history/?' +
                httpBuildQuery({
                  a_id: get_current_user(),
                  p_id: get_selected_party(),
                  namespace: 'rsvp_notes'
                })
            ],
            node: $('#rsvpPanel'),
            append: false
          };
          RenderLazy(config).then(context => {
            user_endpoint_init();
            user_profile_page_init();
          });
        }
      }

      await reload_attendee_essentials();
    },
    interval: 30000
  }).poll('heartbeat');

  control_history($('._fg-public-menu a.active').attr('data-toggle'), 'menu');
  user_endpoint_init();
  user_profile_page_init();

  const p_id = get_selected_party(),
    r_id = get_selected_r_id(),
    party_status = get_party_meta(p_id, 'is_active'),
    activeOrFirstClass =
      party_status == Globals.PARTY_STATUS.active.int ||
      party_status == Globals.PARTY_STATUS.partyover.int ||
      party_status == Globals.PARTY_STATUS.firstclass.int,
    onIce = party_status == Globals.PARTY_STATUS.onice.int;

  collapse_scroll($('#_collapseYourOwnParty'), 70);
  collapse_scroll($('#_collapseWebsiteRefresher'), 200);

  // Scroll down on chat container if exists
  const container = $(`#_chatContainer-${r_id}`);
  if (container.length) {
    container[0].scrollTop = container[0].scrollHeight;
  }

  // Observe mini buttons for image management hints
  let elements = document.querySelectorAll(`.${Globals.STYLES.mini}:not(.d-none) > .${Globals.STYLES.buttonHint}`);
  observeIntersection(
    elements,
    entries => {
      entries.forEach(entry => {
        let elem = $(entry.target);
        elem.toggleClass(Globals.STYLES.fadePulse, entry.isIntersecting);
      });
    },
    {
      root: null,
      rootMargin: '0px',
      threshold: 1.0
    }
  );

  if (!activeOrFirstClass) return;

  const checkRSVPCounts = new Poll({
    fn: reload_rsvp_counts,
    callback: response => {
      // Reveal the badge if it's changed
      var red_dot = $('#_time-to-reload'),
        total_rsvp_count = get_rsvp_count(),
        stale_count = red_dot.attr('data-last-count'),
        delta = Math.max(total_rsvp_count - stale_count, 0);
      red_dot.html(delta).toggleClass('d-none', delta === 0);
    },
    interval: 60000
  });

  checkRSVPCounts.poll('checkRSVPCounts');

  const checkForViewStats = new Poll({
    fn: () => {
      return rest_entry_point(
        'view-stats/?' +
          httpBuildQuery({
            p_id: get_selected_party(),
            a_id: get_current_user()
          })
      );
    },
    callback: response => {
      if (response.response && Object.keys(response.response).length !== 0) {
        Globals.PUBLIC_VIEW_STATS = response.response;
        update_view_stats();
        return response;
      } else {
        throw 'No stats data available';
      }
    },
    interval: 1000 * 60 * 3
  });

  checkForViewStats.poll('checkForViewStats');

  update_elements_after_filter();

  original_grid($('._grid__brick').get());

  var selectParty = $('#_selectedParty');
  if (selectParty.hasClass('_set-your-password')) {
    fg_alert('Remember to set your password in the profile section', () => {
      // Don't annoy them, just do it when they load the page
      selectParty.removeClass('_set-your-password');
    });
  }
}

export function segregate_waitlisters(context) {
  const response = context.response ? context.response : context,
    shuffle = array => {
      for (let i = array.length - 1; i > 0; i--) {
        let j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
      return array;
    };

  // public grid may not be populated, i.e. on ice or party over, etc.
  if (response.public_grid) {
    let waitlisters = shuffle(response.public_grid.filter(gi => gi.reply_n === Globals.REPLY.wait_list.int)),
      nonwaitlisters = shuffle(response.public_grid.filter(gi => gi.reply_n !== Globals.REPLY.wait_list.int));
    response.public_grid = [...waitlisters, ...nonwaitlisters];
  }

  return context.response ? { response: response } : response;
}
