import * as util from '@lib/shared/util';
import Globals from '@lib/globals';
import { update_rsvp_count } from '@lib/shared/filtering';
import { RenderLazy } from '@lib/shared/render';

export function user_endpoint_init() {
  var fresh_yes_rsvp_count = util.get_rsvp_count([Globals.REPLY.fresh_yes.int]),
    r_id = util.get_selected_r_id(),
    reply =
      $(`#rsvpData-${r_id}`)
        .find("input[name='wpcf-rsvp-reply']:checked")
        .val() || $(`#_rsvpData-${r_id}`).attr('data-current-reply'),
    wait_list_count = util.get_rsvp_count(Globals.REPLY.wait_list.int) || 0,
    no_pics = util.get_pic_count() < 1,
    pics_awaiting_approval = $('._fg-under-review').length,
    capacity = util.get_capacity(),
    p_id = util.get_selected_party(),
    party_over = util.get_party_meta(p_id, 'is_active') == Globals.PARTY_STATUS.partyover.int,
    party_on_ice = util.get_party_meta(p_id, 'is_active') == Globals.PARTY_STATUS.onice.int,
    party_inactive = util.get_party_meta(p_id, 'is_active') == Globals.PARTY_STATUS.inactive.int,
    vax_verified = $('._vax-status.verified').length > 1,
    wait_list_in_effect = util.is_waitlist_in_effect(),
    wait_list_almost_in_effect = capacity - fresh_yes_rsvp_count <= 5 && !wait_list_in_effect,
    wait_list_almost_in_effect_count = capacity - fresh_yes_rsvp_count,
    wait_list_text = '',
    wait_list_more_info = `<span style="place-self: center"><button class="fg-waitlist-more-info btn btn-link btn-sm ${Globals.STYLES.plusMinus} collapsed" data-toggle="collapse" data-target="#collapseWaitlistMoreInfo" aria-expanded="false" aria-controls="collapseWaitlistMoreInfo">More Info</button></span>`;

  // Resets for calling this function again
  $('#fg-main-grid')
    .removeClass(Globals.STYLES.noPics)
    .addClass(Globals.STYLES.mainGrid);
  $('[class*="_fg-pic-alert-"],._fg-waitlist-alert,._fg-attendance-alert').addClass('d-none');

  if (!$('body.attendee-master').length && !$('body.launch').length && !$('body.party-orig').length) return;

  if (!vax_verified) {
    wait_list_text =
      'You must acknowledge in writing your vaccination status for both Covid and Monkeypox by emailing Parker or entering in the note below. Until then, you may only reply Waitlist or No.';
  } else if (wait_list_almost_in_effect) {
    wait_list_text = 'There are just ' + wait_list_almost_in_effect_count + ' spots until the wait list takes effect.';
  } else if (wait_list_in_effect && !party_over) {
    // Show main info and grid page alerts
    $('._fg-attendance-alert').removeClass('d-none');
    wait_list_text = `Party is at max capacity. If you choose to be added to the waitlist, you'll be notified as spots open up.`;
    wait_list_text += ' ' + wait_list_more_info;
  }

  // set up the refresh button, which is useless when no-pics
  $('#_publicFilters').toggleClass('d-none', no_pics);

  // Blur for guys with no pics
  no_pics &&
    $('body.party-orig').length &&
    $('#fg-main-grid')
      .toggleClass(Globals.STYLES.noPics, no_pics)
      .addClass(Globals.STYLES.mainGrid) &&
    !$('head').find('style#blurred').length &&
    !util.admin_logged_in() &&
    blur_public_grid();

  if (no_pics && pics_awaiting_approval) {
    $('._fg-pic-alert-pending-approval').removeClass('d-none');
  } else if (no_pics) {
    $('._fg-pic-alert-no-pics').removeClass('d-none');
  }

  if (reply == Globals.REPLY.no_reply.int || reply == Globals.REPLY.no.int || reply == Globals.REPLY.wait_list.int) {
    // If no pics, show the tip
    if (no_pics) {
      $('._fg-pic-alert-in-grid-tip').removeClass('d-none');
    }

    // Wait list in effect, either no pics and haven't already replied yes/maybe
    // Radio behavior below will override
    if (wait_list_almost_in_effect || wait_list_in_effect || !vax_verified) {
      $('._fg-waitlist-alert')
        .removeClass('d-none')
        .find('._alert-text')
        .html(wait_list_text);
      util.collapse_scroll($('#collapseWaitlistMoreInfo'), 50);
    }
  }

  // Hide alerts that are not relevant in these states
  // Don't toggle, it will show ones you don't need
  if (party_on_ice || party_over || party_inactive) {
    $('._fg-waitlist-alert,._fg-attendance-alert').addClass('d-none');
  }

  determine_rsvp_selection_state(reply, r_id);

  // Fill in blank inputs by adding :before class
  $('._fg-inline-value-placeholder').each(function(idx) {
    if (
      $(this)
        .html()
        .trim() == ''
    ) {
      $(this).addClass('placeholder');
    }
  });
  $('btn-default.outline').addClass(`${Globals.STYLES.blurredBackdrop}`);
}

function blur_public_grid() {
  $('#lastGridCell').remove();

  var bricks = $('#fg-main-grid ._grid__brick'),
    getDataURI = img_src => {
      var canvas = $('<canvas width="200" height="200"></canvas>')[0],
        ctx = canvas.getContext('2d'),
        img = new Image();
      return new Promise((resolve, reject) => {
        img.onload = function() {
          ctx.drawImage(img, 0, 0);
          var dataURI = canvas.toDataURL('image/webp', 0.05);
          resolve(dataURI);
        };
        img.src = img_src;
      });
    },
    injectCSS = css_arr => {
      // get the head and create a style element
      var head = document.head || document.getElementsByTagName('head')[0];
      var style = document.createElement('style');

      style.type = 'text/css';
      style.id = 'blurred';

      // create your CSS as a string
      var css = css_arr.join(' ');

      // IE8 and below.
      if (style.styleSheet) {
        style.styleSheet.cssText = css;
      } else {
        style.appendChild(document.createTextNode(css));
      }

      // add it to the head
      head.appendChild(style);
    };

  (async function() {
    const data = [],
      injectableStyles = [];

    // Collect the data
    $.each(bricks, (i, brick) => {
      var picture = $(brick).find('picture'),
        srcSets = picture
          .find('source')
          .first()
          .attr('srcset'),
        srcSetArr = srcSets ? srcSets.split(' ') : [],
        a_id = $(brick).attr('data-attendee-id'),
        currentSrc = srcSetArr[2],
        record = {};

      record.a_id = a_id;
      record.currentSrc = currentSrc;
      data.push(record);
    });

    // Send them all at once
    await Promise.all(
      data.map(async source => {
        var dataURI = await getDataURI(source.currentSrc);
        // noPics is composes so much be joined
        var noPics = Globals.STYLES.noPics.split(' ').join('.');
        var css = `#fg-main-grid.${noPics} .${Globals.STYLES.gridBrick}[data-attendee-id="${source.a_id}"]:before {background-image: url("${dataURI}");}`;
        injectableStyles.push(css);
      })
    );

    // Empty at the last minute to avoid flashing
    $('#fg-main-grid ._grid__brick picture,#fg-main-grid ._grid__brick [class*="_fg-caption-"]').remove();

    fg_console(injectableStyles);

    if (injectableStyles.length) injectCSS(injectableStyles);
  })();
}

export function determine_rsvp_selection_state(reply, r_id) {
  /* Determine state of rsvp radios based on reply */
  var // Groups
    rsvp_count_group = $('._input-group-wpcf-rsvp-count'),
    rsvp_note = $('._input-group-wpcf-note'),
    // Replies
    base = '._radio-input-rsvp-reply-',
    r = Globals.REPLY,
    yes_radio = $(base + r.fresh_yes.int),
    maybe_radio = $(base + r.maybe.int),
    no_radio = $(base + r.no.int),
    no_reply = $(base + r.no_reply.int),
    firm_yes = $(base + r.firm_yes.int),
    wait_list_radio = $(base + r.wait_list.int),
    yes_maybe_and_no_radio = yes_radio.add(no_radio).add(maybe_radio),
    wait_list_in_effect = util.is_waitlist_in_effect(),
    is_admin = util.admin_logged_in(),
    vax_verified = $('._vax-status.verified').length > 1,
    r_id = r_id || util.get_selected_r_id(),
    notification_status = $(`#_rsvpData-${r_id}`).attr('data-notification-status'),
    off_the_waitlist = notification_status == Globals.NOTIFICATION_STATUS.waitlisted.int,
    off_the_waitlist_text = "Good news: You're off the wait list and can change your reply now",
    waitlist_alert = $('#rsvpPanel ._fg-waitlist-alert'),
    all = rsvp_count_group
      .add(rsvp_note)
      .add(yes_maybe_and_no_radio)
      .add(wait_list_radio)
      .add(no_reply)
      .add(firm_yes);

  if (is_admin) {
    all.removeClass('d-none');
    $(`#_rsvpData-${r_id}`).addClass(Globals.STYLES.three);
    return true;
  }

  // All off first
  all.addClass('d-none');

  var visibility = function(items, is_show) {
    $(items).each((i, itm) => $(itm).toggleClass('d-none', !is_show));
  };

  visibility(rsvp_note, true);
  visibility(rsvp_count_group, true);

  // Default button state, may be overridden if wait list in effect, see below
  switch (parseInt(reply)) {
    case Globals.REPLY.no_reply.int:
      visibility(yes_maybe_and_no_radio, true);
      if (wait_list_in_effect || !vax_verified) {
        visibility(rsvp_count_group, false);
        visibility(wait_list_radio.add(no_radio), true);
        visibility(yes_radio.add(maybe_radio), false);
      }
      break;
    case Globals.REPLY.fresh_yes.int:
    case Globals.REPLY.maybe.int:
      visibility(yes_maybe_and_no_radio, true);
      break;
    case Globals.REPLY.no.int:
      visibility(rsvp_count_group, false);
      visibility(yes_maybe_and_no_radio, true);
      if (wait_list_in_effect || !vax_verified) {
        visibility(wait_list_radio.add(no_radio), true);
        visibility(yes_radio.add(maybe_radio), false);
      }
      break;
    case Globals.REPLY.wait_list.int:
      // Released from waitlist email has been sent
      if (wait_list_in_effect && off_the_waitlist && vax_verified) {
        waitlist_alert
          .html(off_the_waitlist_text)
          .addClass(`_fg-alert ${Globals.STYLES.fgAlert_success}`)
          .removeClass(`${Globals.STYLES.fgAlertSm_danger} ${Globals.STYLES.fgAlertSm_warning}`);
        visibility(yes_maybe_and_no_radio, true);
        break;
      }
      visibility(rsvp_count_group, false);
      visibility(yes_radio.add(maybe_radio), false);
      visibility(wait_list_radio.add(no_radio), true);
      break;
  }

  var r_id = util.get_selected_r_id(),
    numberRemaining = $(`#_rsvpData-${r_id} > *:not(.d-none)`).length,
    columns = 'three';
  switch (numberRemaining) {
    case 1:
      columns = 'one';
      break;
    case 2:
      columns = 'two';
      break;
    case 3:
      columns = 'three';
      break;
    case 4:
      columns = 'four';
      break;
  }
  $(`#_rsvpData-${r_id}`)
    .removeClass(`${Globals.STYLES.one} ${Globals.STYLES.two} ${Globals.STYLES.three} ${Globals.STYLES.four}`)
    .addClass(Globals.STYLES[columns]);
}

export function user_profile_page_init() {
  // Fill up the hidden inlines
  $('._fg-edit-inline').each((idx, inline) => {
    var data_entry_reveal = [],
      inline = $(inline),
      placeholder_data = inline.attr('data-placeholder') || '';

    data_entry_reveal.push(
      `<span class="_fg-inline-value-placeholder ${Globals.STYLES.inlinePlaceholder} ${Globals.STYLES.blurred_info}"></span>`
    );
    data_entry_reveal.push(
      `<span class="btn btn-link btn-sm _fg-edit-inline-btn-pencil"><i class="fas fa-pencil-alt"></i></span>`
    );
    data_entry_reveal.push(
      `<span class="btn btn-outline-light _fg-edit-inline-btn-cancel d-none handle-click ${Globals.STYLES.inlineButton}" data-module="handle_inline_cancel">Cancel</span>`
    );
    data_entry_reveal.push(
      `<span class="btn btn-success _fg-edit-inline-btn-submit d-none handle-click ${Globals.STYLES.inlineButton}" data-module="handle_inline_submit">Save</span>`
    );

    var reveal_elem = $(data_entry_reveal.join(''));

    inline.html(reveal_elem);
    inline.find('._fg-inline-value-placeholder').html(placeholder_data);
  });

  $('._fg-inline-value-placeholder').each(function(idx3) {
    if (
      $(this)
        .html()
        .trim() == ''
    ) {
      $(this)
        .html('Not set')
        .addClass('text-muted');
    }
  });
}

export function init_batch_selections(p_id) {
  var a_id_arr = [];

  $('._grid__brick').each(function(idx, attendee) {
    var a_id = $(attendee)
        .find('._fg-modal-on-demand')
        .data('attendee-id'),
      party_meta = util.get_parties_attended(a_id, p_id),
      rsvp_exists = typeof party_meta !== 'undefined',
      is_host = rsvp_exists && party_meta['is_host'],
      host_indicator = is_host ? '<span class="host">HOST</span>' : '',
      batch_mode_active = $('#_collapseBatchActions').hasClass('show');

    $(attendee)
      .find('._fg-single-image-public')
      .toggleClass('batch-selected', rsvp_exists && batch_mode_active)
      .parent('._fg-thumb')
      .append(host_indicator);

    if (!is_host) {
      $(attendee)
        .find('.host')
        .remove();
    }

    if (!batch_mode_active) {
      return;
    }

    if (rsvp_exists) {
      a_id_arr.push(a_id);
    }
  });
  $('#fg-main-grid').attr('data-batch-attendees', a_id_arr.join(','));
}

export function update_launch_notify_buttons() {
  var determine_state = (btn, notif_stat_n, reply_n, generic_indicator) => {
    var btn_class = $(btn).attr('data-type'),
      btn = $(btn);

    const show_when = {
      initial_invite: {
        notification_status: [Globals.NOTIFICATION_STATUS.initialized.int],
        reply: [Globals.REPLY.no_reply.int]
      },
      reminder_yes_maybe: {
        notification_status: [Globals.NOTIFICATION_STATUS.invited.int],
        reply: [Globals.REPLY.firm_yes.int, Globals.REPLY.fresh_yes.int, Globals.REPLY.maybe.int]
      },
      reminder_no_reply: {
        notification_status: [Globals.NOTIFICATION_STATUS.invited.int],
        reply: [Globals.REPLY.no_reply.int]
      },
      room_number: {
        notification_status: [
          Globals.NOTIFICATION_STATUS.confirmed.int,
          Globals.NOTIFICATION_STATUS.reminded.int,
          Globals.NOTIFICATION_STATUS.waitlisted.int // means you've been sent the off the waitlist email
        ],
        reply: [Globals.REPLY.firm_yes.int, Globals.REPLY.fresh_yes.int, Globals.REPLY.maybe.int]
      },
      wait_list: {
        notification_status: [Globals.NOTIFICATION_STATUS.invited.int, Globals.NOTIFICATION_STATUS.reminded.int],
        reply: [Globals.REPLY.wait_list.int]
      },
      generic: {
        notification_status: [
          Globals.NOTIFICATION_STATUS.initialized.int,
          Globals.NOTIFICATION_STATUS.invited.int,
          Globals.NOTIFICATION_STATUS.reminded.int,
          Globals.NOTIFICATION_STATUS.confirmed.int,
          Globals.NOTIFICATION_STATUS.roomed.int,
          Globals.NOTIFICATION_STATUS.waitlisted.int
        ],
        reply: [
          Globals.REPLY.firm_yes.int,
          Globals.REPLY.fresh_yes.int,
          Globals.REPLY.maybe.int,
          Globals.REPLY.wait_list.int,
          Globals.REPLY.no_reply.int
        ]
      }
    };

    var statusObj = show_when[btn_class],
      ns_arr = statusObj['notification_status'],
      reply_arr = statusObj['reply'],
      matches = ns_arr.includes(notif_stat_n) && reply_arr.includes(reply_n),
      generic_active = $('#_activateGeneric').is(':checked'),
      generic_subject = $('#_genericSubject'),
      a_id = btn.attr('data-attendee-id'),
      p_id = util.get_selected_party(),
      party_meta = util.get_parties_attended(a_id, p_id),
      exclude_not_first_class = party_meta['first_class'] === false;

    if (btn.is('._fg-generic')) {
      btn.add(generic_subject).toggleClass('d-none', !generic_active);
      btn.toggleClass('disabled', generic_indicator != 0);
    } else {
      btn.toggleClass('d-none', !matches || generic_active).toggleClass('_first-class-only', exclude_not_first_class);
    }
  };

  $.each($('._fg-table-row'), (idx, itm) => {
    var reply = $(itm).data('reply'),
      generic_indicator = $(itm).attr('data-generic-email-indicator'),
      notification_status = $(itm).data('notification-status'),
      child_buttons = $(itm).find('._fg-send-single-notification');
    $.each(child_buttons, (i, button) => {
      determine_state(button, notification_status, reply, generic_indicator);
    });
  });
}

export function update_view_stats() {
  const viewstats = Globals.PUBLIC_VIEW_STATS,
    myself = util.get_current_user();
  $('.fg-view-count').remove();
  $('._grid__brick ._fg-single-image-public').each((i, itm) => {
    var grid_a_id = $(itm).attr('data-attendee-id'),
      viewed = viewstats && viewstats.viewed_by,
      counts = (viewed && viewed[grid_a_id] && viewed[grid_a_id].count) || 0;
    if (myself == grid_a_id) {
      return true;
    }
    if (counts > 0) {
      const indicator = `
        <span class="fg-view-count ${Globals.STYLES.viewCount} animated slideInDown">
          <span class="${Globals.STYLES.captionPipe}">|</span>
          <span class="fas fa-eye"></span>
          <span>${counts}</span>
        </span>`;
      $(itm)
        .find('._fg-caption')
        .append(indicator);
    }
  });
}

export async function adjust_page_for_global_notice() {
  // This is only necessary when menu is fixed
  return;
  let offset = await util.menuOffset();
  $('[class*="_fg-public-section-"]')
    .add('._fg-public-footer')
    .css('padding-top', offset + 'px');
}

export function party_menu_init() {
  // https://medium.com/javascript-in-plain-english/how-to-check-for-a-number-in-javascript-8d9024708153
  const isNumber = value => typeof value === 'number' && value === value && value !== Infinity && value !== -Infinity;

  var hash = 'info';
  if (window.location.hash.substr(1) !== '') {
    hash = window.location.hash.substr(1);

    // Assume we're in grid detail
    if (isNumber(parseInt(hash))) {
      hash = 'grid';
    }
    // remove the default
    $('._fg-public-menu a.btn').removeClass(
      `active ${Globals.STYLES.active} fg-btn-selected ${Globals.STYLES.fgButtonSelected}`
    );
    $('._fg-public-menu a.btn[data-toggle="_fg-public-section-' + hash + '"]').addClass(
      `active ${Globals.STYLES.active} fg-btn-selected ${Globals.STYLES.fgButtonSelected}`
    );
  }

  // Set up which section should be shown
  $('._fg-public-menu a.btn').each(function(idx, btn) {
    if ($(btn).hasClass('active')) {
      var section_to_show = $(btn).attr('data-toggle');
      $('.' + section_to_show).removeClass('d-none');
    }
  });
}

export function sortByRsvpTimestamp(a, b) {
  var p_id = util.get_selected_party(),
    a_id_a = $(a)
      .find('._fg-modal-on-demand')
      .attr('data-attendee-id'),
    a_id_b = $(b)
      .find('._fg-modal-on-demand')
      .attr('data-attendee-id'),
    party_meta_a = util.get_parties_attended(a_id_a, p_id),
    party_meta_b = util.get_parties_attended(a_id_b, p_id);

  try {
    return party_meta_b.rsvp_t - party_meta_a.rsvp_t;
  } catch (e) {
    return 0;
  }
}

export function grid_item_progress(nearest_picture) {
  nearest_picture = $(nearest_picture);
  nearest_picture.toggleClass(Globals.STYLES.pictureProgress);
}

export function toggle_enlarged_image(show) {
  $('#_fg-enlarge').toggleClass('animated fadeIn _enlarge-active', show);
  $('.dropzone').toggleClass('d-none', show);
  $('._enlarge-on-demand').toggleClass(Globals.STYLES.enlarge, show);
}

export function listen_for_card_expansions() {
  $('#parties-attended-accordion')
    .find('.collapse[id*=rsvp-toggle]')
    .on('shown.bs.collapse', function() {
      // Lazy Load the crus rsvp and notes
      var p_id = $(this)
          .parents('.card')
          .attr('data-party-id'),
        target = $(this).find(`#_rsvp-card-container-${p_id}`),
        a_id = $('#_fg-attendee-detail').attr('data-attendee-id');

      let config = {
        templatePath: 'partials/crud-user-rsvp-data',
        context: [
          'ui-crud-user-rsvp/?' +
            util.httpBuildQuery({
              namespace: 'user_rsvp',
              a_id: a_id,
              p_id: p_id
            }),
          'ui-note-history/?' +
            util.httpBuildQuery({
              a_id: a_id,
              p_id: p_id,
              namespace: 'rsvp_notes'
            })
        ],
        node: target
      };

      RenderLazy(config)
        .then(context => {
          var r_id = context.response.user_rsvp.r_id;
          user_profile_page_init();
          user_endpoint_init();
          const container = $(`#_chatContainer-${r_id}`);
          if (container.length) {
            container[0].scrollTop = container[0].scrollHeight;
          }
        })
        .finally(() => {
          target.removeClass('invisible');
        });
    });
}
