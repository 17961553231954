import { library, dom, config as faConfig } from '@fortawesome/fontawesome-svg-core';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons/faArrowLeft';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons/faArrowUp';
import { faBan } from '@fortawesome/free-solid-svg-icons/faBan';
import { faCertificate } from '@fortawesome/free-solid-svg-icons/faCertificate';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { faCircle } from '@fortawesome/free-solid-svg-icons/faCircle';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons/faCircleNotch';
import { faClock as fasFaClock } from '@fortawesome/free-solid-svg-icons/faClock';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons/faExclamationCircle';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons/faExclamationTriangle';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons/faExternalLinkAlt';
import { faEye } from '@fortawesome/free-solid-svg-icons/faEye';
import { faHourglassStart } from '@fortawesome/free-solid-svg-icons/faHourglassStart';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle';
import { faLock } from '@fortawesome/free-solid-svg-icons/faLock';
import { faMap } from '@fortawesome/free-solid-svg-icons/faMap';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons/faMapMarkerAlt';
import { faMinus } from '@fortawesome/free-solid-svg-icons/faMinus';
import { faMobileAlt } from '@fortawesome/free-solid-svg-icons/faMobileAlt';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons/faPencilAlt';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { faPray } from '@fortawesome/free-solid-svg-icons/faPray';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons/faQuestionCircle';
import { faRandom } from '@fortawesome/free-solid-svg-icons/faRandom';
import { faSearchPlus } from '@fortawesome/free-solid-svg-icons/faSearchPlus';
import { faShare } from '@fortawesome/free-solid-svg-icons/faShare';
import { faSms } from '@fortawesome/free-solid-svg-icons/faSms';
import { faStar as fasFaStar } from '@fortawesome/free-solid-svg-icons/faStar';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons/faSyncAlt';
import { faTags } from '@fortawesome/free-solid-svg-icons/faTags';
import { faThumbsUp } from '@fortawesome/free-solid-svg-icons/faThumbsUp';
import { faThumbsDown as fasThumbsDown } from '@fortawesome/free-solid-svg-icons/faThumbsDown';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons/faTimesCircle';
import { faUpload } from '@fortawesome/free-solid-svg-icons/faUpload';
import { faUser } from '@fortawesome/free-solid-svg-icons/faUser';
import { faUsers } from '@fortawesome/free-solid-svg-icons/faUsers';
import { faUnlock } from '@fortawesome/free-solid-svg-icons/faUnlock';

import { faStackpath } from '@fortawesome/free-brands-svg-icons/faStackpath';
import { faTwitter } from '@fortawesome/free-brands-svg-icons/faTwitter';
import { faSnapchatGhost } from '@fortawesome/free-brands-svg-icons/faSnapchatGhost';

import { faArrowAltCircleRight } from '@fortawesome/free-regular-svg-icons/faArrowAltCircleRight';
import { faArrowAltCircleLeft } from '@fortawesome/free-regular-svg-icons/faArrowAltCircleLeft';
import { faClock as farFaClock } from '@fortawesome/free-regular-svg-icons/faClock';
import { faCompass } from '@fortawesome/free-regular-svg-icons/faCompass';
import { faCopy } from '@fortawesome/free-regular-svg-icons/faCopy';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons/faEnvelope';
import { faEyeSlash } from '@fortawesome/free-regular-svg-icons/faEyeSlash';
import { faFrown } from '@fortawesome/free-regular-svg-icons/faFrown';
import { faHourglass } from '@fortawesome/free-regular-svg-icons/faHourglass';
import { faSmile } from '@fortawesome/free-regular-svg-icons/faSmile';
import { faStar as farFaStar } from '@fortawesome/free-regular-svg-icons/faStar';
import { faSquare } from '@fortawesome/free-regular-svg-icons/faSquare';
import { faThumbsDown as farThumbsDown } from '@fortawesome/free-regular-svg-icons/faThumbsDown';
import { faTimesCircle as farTimesCircle } from '@fortawesome/free-regular-svg-icons/faTimesCircle';

library.add(
  faArrowLeft,
  faArrowUp,
  faArrowAltCircleRight,
  faArrowAltCircleLeft,
  faBan,
  faCertificate,
  faCheck,
  faCheckCircle,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faCircle,
  faCircleNotch,
  fasFaClock,
  farFaClock,
  faCompass,
  faCopy,
  faEnvelope,
  faExclamationCircle,
  faExclamationTriangle,
  faExternalLinkAlt,
  faEye,
  faEyeSlash,
  faFrown,
  faHourglass,
  faHourglassStart,
  faInfoCircle,
  faLock,
  faMap,
  faMapMarkerAlt,
  faMinus,
  faMobileAlt,
  faPencilAlt,
  faPlus,
  faPray,
  faQuestionCircle,
  faRandom,
  faSearchPlus,
  faShare,
  faSmile,
  faSms,
  faSquare,
  faStackpath,
  fasFaStar,
  farFaStar,
  faSyncAlt,
  faTags,
  faThumbsUp,
  farThumbsDown,
  fasThumbsDown,
  faTimes,
  faTimesCircle,
  farTimesCircle,
  faTwitter,
  faSnapchatGhost,
  faUnlock,
  faUpload,
  faUser,
  faUsers
);
faConfig.searchPseudoElements = false;
var faDom = dom;

export { faConfig, faDom };
