import { getModuleName } from '@lib/shared/util';
import common from '@src/routes/common';
import loadModule from '@lib/shared/loadModule';

export default class Router {
  constructor(routes) {
    this.routes = routes;
  }
  loadEvents() {
    common();
    const page_route = getModuleName();
    if (page_route) {
      (async () => {
        const loaded_route = await loadModule('route', page_route);
        const pageObj = new loaded_route.default(page_route);
        pageObj.http();
      })();
    } else {
      throw new Error(`Invalid page route: ${page_route}`);
    }
  }
}
