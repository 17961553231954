import Globals from '@lib/globals';
import * as util from '@lib/shared/util';
import { init_batch_selections, update_view_stats } from '@lib/shared/state';

/** Used for delegating a matching function when searching **/
function matchesFilter(element, matches, searchTerm) {
  var oppositeOf = searchTerm ? searchTerm.startsWith('-') : false,
    searchTerm = oppositeOf ? searchTerm.substr(1) : searchTerm;
  if (matches && matches instanceof Function) {
    // If it starts with - then flip the logic
    var function_matches = matches(element, searchTerm);
    return (function_matches && !oppositeOf) || (!function_matches && oppositeOf);
  }
  return false;
}

export function update_position_counts() {
  let collapse_trigger = $('span[data-target="#collapsePositions"]'),
    collapse_target = $(collapse_trigger.attr('data-target'));
  $('button._btn-position').each(function(idx, btn) {
    btn = $(btn);
    var pos = btn.attr('data-name'),
      breadcrumb_terms = $('._fg-breadcrumb')
        .get()
        .map(bc => $(bc).attr('data-filter-value')),
      count = $('#fg-main-grid ._grid__brick ._fag-position').filter(function(index, elem) {
        return $(elem).attr('data-name') == pos;
      }).length;
    $(this)
      .toggleClass('d-none', count < 1)
      .find('._button-count')
      .html(count);
  });
}

export function update_tag_counts() {
  let collapse_trigger = $('span[data-target="#collapseTags"]');
  $('._fg-tag-btn').each(function(idx, btn) {
    btn = $(btn);
    var tag = btn.attr('data-term'),
      breadcrumb_terms = $('._fg-breadcrumb')
        .get()
        .map(bc => $(bc).attr('data-filter-value')),
      found = $('#fg-main-grid ._grid__brick ._fg-thumb').filter(function(index, elem) {
        let terms = $(elem).attr('data-terms');
        return terms && terms.indexOf(tag) > -1;
      });
    btn
      .toggleClass('d-none', found.length < 1)
      .find('._button-count')
      .html(found.length);
  });
}

export function update_attendee_count() {
  if (!$('body.attendee-master').length) {
    return;
  }
  var unsubs = $(
    '._fg-grid-wrapper.valid-only>._grid__brick:is([data-rating="0"],[data-rating="1"],[data-rating="2"],[data-post-status=pending])'
  ).length;
  if (!$('#_checkValid').is(':checked')) {
    unsubs = 0;
  }
  var counter = $('#fg-main-grid ._grid__brick').length;
  $('._printCounter').html(counter - unsubs + ' Attendees');
}

export function update_rsvp_count(includeFilteredCount) {
  var styles = Globals.STYLES,
    is_admin = util.admin_logged_in(),
    counts = {
      rsvp: [
        {
          human: 'Yes',
          style: styles.countYes,
          admin_only: true,
          count: util.get_rsvp_count([Globals.REPLY.firm_yes.int, Globals.REPLY.fresh_yes.int])
        },
        {
          human: 'Maybe',
          style: styles.countMaybe,
          admin_only: true,
          count: util.get_rsvp_count(Globals.REPLY.maybe.int)
        },
        { human: 'No', style: styles.countNo, admin_only: true, count: util.get_rsvp_count([Globals.REPLY.no.int]) },
        {
          human: 'Waitlist',
          style: styles.countWaitlist,
          admin_only: true,
          count: util.get_rsvp_count([Globals.REPLY.wait_list.int])
        },
        {
          human: 'RSVPs',
          style: styles.countYes,
          admin_only: false,
          count: util.get_rsvp_count([Globals.REPLY.firm_yes.int, Globals.REPLY.fresh_yes.int, Globals.REPLY.maybe.int])
        }
      ],
      filtered:
        includeFilteredCount && $('#filterBreadcrumb > ._fg-breadcrumb').length > 0
          ? [{ human: 'Filtered', style: styles.countMaybe, count: $('#fg-main-grid ._grid__brick').length }]
          : []
    };

  $('._printCounterRSVP').each(function(idx, itm) {
    let render = [];
    counts.rsvp
      .filter(c => c.admin_only === is_admin)
      .forEach((countObj, i) => {
        render.push(
          `<div class="text-center"><div class="${countObj.style} mb-0 h5">${countObj.count}</div><div>${countObj.human}</div></div>`
        );
      });
    counts.filtered.forEach((countObj, i) => {
      render.push(
        `<div class="text-center"><div class="${countObj.style} mb-0 h5">${countObj.count}</div><div>${countObj.human}</div></div>`
      );
    });
    $(itm).html(render.join(''));
  });
}

export function delay_keystroke(e, ms) {
  e.stopImmediatePropagation();
  const localStorageKey = 'delay_keystroke',
    input = $(e.currentTarget)
      .val()
      .trim(),
      currentTimer = window.localStorage.getItem(localStorageKey);

    currentTimer && clearTimeout(currentTimer);

    return new Promise( (resolve, reject) => {
      if ( input.length < 3 && input.length !== 0 ) {
        reject( "Input too short" );
        return;
      }
      let promiseTimer = setTimeout(() => {
        let timerAfterTimeout = window.localStorage.getItem(localStorageKey);
        if ( timerAfterTimeout === undefined || parseInt(timerAfterTimeout) === parseInt(promiseTimer) ) {
          resolve(promiseTimer);
        } else {
          reject({timerAfterTimeout: timerAfterTimeout, promiseTimer: promiseTimer});
        }
      }, ms || 1000 );
      window.localStorage.setItem(localStorageKey, promiseTimer);
    });

}

/*
 const args = {
  reset: null,
  matchFunction: null,
  sortFunction: null,
  searchConstruct: null
};
*/
export function searchAndRender(options) {
  let { reset, matchFunction, searchConstruct, sortFunction } = options;

  var animation_entrances = [
      'fadeIn',
      'fadeInBottomLeft',
      'fadeInBottomRight',
      'fadeInDown',
      'fadeInLeft',
      'fadeInRight',
      'fadeInTopLeft',
      'fadeInTopRight',
      'fadeInUp',
      'slideInDown',
      'slideInLeft',
      'slideInRight',
      'slideInUp'
    ],
    locked_indicator = $('#_filterLock').attr('data-lock'),
    locked = false,
    matchCount = 0,
    local_fragment = new DocumentFragment(),
    candidates = [];

  function lookup(_grid__brick) {
    return $(_grid__brick)
      .find('._fg-modal-on-demand')
      .attr('data-attendee-id');
  }

  function animateSearch(original_item) {
    var random = Math.floor(Math.random() * animation_entrances.length),
      grid_item = $(original_item);
    grid_item
      .removeClass(function(index, className) {
        return animation_entrances.concat(['animated', 'faster']);
      })
      .addClass('animated faster ' + animation_entrances[random]);
    return grid_item.get(0);
  }

  switch (locked_indicator) {
    case 'true':
      candidates = locked_candidates();
      locked = true;
      break;
    case 'always':
      $('#_filterLock').attr('data-lock', 'true');
      locked = true;
    case 'false':
      candidates = original_grid();
      break;
  }

  if (reset) {
    return new Promise((resolve, reject) => {
      const og = Globals.ORIGINAL_GRID.cloneNode(true);
      candidates = original_grid();
      $('._fg-grid-wrapper').html(og);
      $('#filterBreadcrumb').empty();
      Globals.LOCKED_CANDIDATES = og;
      $('#_fg-search-box,._atm-buttons .btn,._atm-buttons input,._fg-filter .btn').removeClass('disabled');
      resolve(og);
    });
  }

  const promiseFilterFunc = (resolve, reject) => {
    var matched_candidates = candidates.filter(original_item => {
      if (searchConstruct) {
        var match_found = false;
        switch (searchConstruct.operator) {
          case Globals.AND:
            $.each(searchConstruct.terms, function(idx, searchTerm) {
              if (
                matchesFilter(original_item, matchFunction, searchTerm) &&
                ++matchCount === searchConstruct.terms.length
              ) {
                match_found = true;
                return false; // break
              }
            });
            break;
          default:
            // OR is effectively the same as a single non-operator search
            $.each(searchConstruct.terms, function(idx, searchTerm) {
              if (matchesFilter(original_item, matchFunction, searchTerm) && ++matchCount > 0) {
                match_found = true;
                return false; // break
              }
            });
        }
        if (match_found) {
          local_fragment.appendChild(animateSearch(original_item));
        }
        return match_found;
      }
      var matches_filter = matchesFilter(original_item, matchFunction);
      if (matches_filter) {
        local_fragment.appendChild(animateSearch(original_item));
      }
      return matches_filter;
    });

    if (sortFunction) {
      var frag = new DocumentFragment();
      var sorted = Array.from(local_fragment.cloneNode(true).childNodes).sort(sortFunction);
      sorted.forEach(function(el) {
        frag.appendChild(el);
      });
      $('._fg-grid-wrapper').html(frag.cloneNode(true));
    } else {
      $('._fg-grid-wrapper').html(local_fragment.cloneNode(true));
    }

    if (locked) {
      locked_candidates(matched_candidates.length > 0 ? matched_candidates : []);
    }

    resolve(Globals.LOCKED_CANDIDATES);
  };

  return new Promise(promiseFilterFunc);
}

function update_email_address_format() {
  var arr = [];
  $('._fg-attendee-master ._fg-searchable').each(function(idx, itm) {
    var email = $(itm).attr('data-email');
    if (email && email != '' && email != 'null@null.com') {
      arr.push(email);
      arr.push(',');
    }
  });
  $('._copy-emails').attr('data-clipboard-text', arr.join(''));
  util.copy_emails();
}

export function update_elements_after_filter() {
  util.blur_exceptions();
  util.fix_lowres_images('#fg-main-grid ._fg-image-wrap');
  update_view_stats();
  update_attendee_count();
  update_rsvp_count(true);
  update_email_address_format();
  update_position_counts();
  update_tag_counts();
}

export function add_filter_breadcrumb(breadcrumb, filter_key, filter_value) {
  const locked = $('#_filterLock').attr('data-lock') != 'false',
    html = `
          <span class="_fg-breadcrumb ${Globals.STYLES.breadcrumb} handle-click" data-module="handle_remove_filter_breadcrumb" data-filter-key="${filter_key}" data-filter-value="${filter_value}">
            <span class="label">${breadcrumb}</span>
            <span class="${Globals.STYLES.removeBreadcrumb} far fa-times-circle ml-1"></span>
          </span>`,
    footnote = Globals.LOCKED_FILTERS.length == 0 ? `<small>* Multiple filter selections OK</small>` : '';
  if (locked && !Globals.LOCKED_FILTERS.includes(html)) {
    Globals.LOCKED_FILTERS.push(html);
  }
  if (!locked) {
    Globals.LOCKED_FILTERS = [html];
  }
  $('#filterBreadcrumb')
    .removeClass('d-none')
    .html(Globals.LOCKED_FILTERS.join(`<i class="fas fa-plus _icon"></i>`))
    .append(footnote)
    .prepend(`<span class="text-center" style="flex-basis: 100%">Filtering on:</span>`);
}

export function original_grid(nodes) {
  if (nodes) {
    const df = new DocumentFragment();
    nodes.forEach(node => {
      if (Array.isArray(node)) {
        node.forEach(c => df.appendChild(c.cloneNode(true)));
      } else {
        df.appendChild(node.cloneNode(true));
      }
    });
    Globals.ORIGINAL_GRID = Globals.LOCKED_CANDIDATES = df;
    // We mustn't store all locked candidates if attendee master
    if ($('body.attendee-master')) Globals.LOCKED_CANDIDATES = new DocumentFragment();
    return;
  }
  const df = Globals.ORIGINAL_GRID,
    array_of_nodes = Array.from(df.cloneNode(true).childNodes);
  return array_of_nodes;
}

export function locked_candidates(node) {
  if (node) {
    Globals.LOCKED_CANDIDATES = new DocumentFragment();
    $.each(node, (idx, itm) => {
      Globals.LOCKED_CANDIDATES.appendChild(
        $(itm)
          .clone()
          .get(0)
      );
    });
    return;
  }
  let locked = Globals.LOCKED_CANDIDATES,
    array_of_nodes = Array.from(locked.cloneNode(true).childNodes);
  return array_of_nodes;
}

export function reset_filter_rsvp(element) {
  $('.fg-rsvp-single-filter :checkbox, .fg-rsvp-single-filter :radio').attr('checked', false);
  $('#_partySelectDropdown').val(util.get_selected_party() || 0);
  return Promise.resolve(element);
}

export function reset_locks() {
  Globals.LOCKED_FILTERS = [];
  $('#_filterLock')
    .attr('data-lock', 'true')
    .trigger('click');
}

export function searchbox_filter(element, searchingForFromConstruct) {
  var a_id = $(element)
    .find('._fg-modal-on-demand')
    .data('attendee-id');

  // Check for possible SMS search
  var possibleSMS = searchingForFromConstruct.match(/\d/g);
  if (possibleSMS && possibleSMS.length > 3) {
    searchingForFromConstruct = searchingForFromConstruct.replace(/[^\d]/g, '');
  }

  // Check for hint tag
  var possibleHint = searchingForFromConstruct.split(':'),
    isPossibleHint = possibleHint.length === 2;
  if (isPossibleHint) {
    searchingForFromConstruct = possibleHint[1];
  }

  // Check for exact match
  var exactMatch = searchingForFromConstruct.match(/^"(.*)"$/),
    isExactMatch = exactMatch !== null;
  if (isExactMatch) {
    searchingForFromConstruct = exactMatch[1];
  }

  // Get filterable items for this a_id
  const found_terms = Globals.ATM_SEARCHABLE_DATA.filter(terms => terms.id === a_id).filter(terms => {
    let found = false;
    for (const [key, value] of Object.entries(terms)) {
      let matches_broad =
          isExactMatch === false &&
          value
            .toString()
            .toLowerCase()
            .indexOf(searchingForFromConstruct) > -1,
        matches_exact = isExactMatch === true && value.toString().toLowerCase() == searchingForFromConstruct;

      if (matches_broad || matches_exact) {
        // If you specify a hint, i.e. "sms:" and it doesn't match, keep going
        if (isPossibleHint && key != possibleHint[0]) {
          continue;
        }

        found = true;
        break;
      }
    }
    return found;
  });

  return found_terms.length > 0;
}

export function create_search_construct(searchingFor) {
  const searchAnd = searchingFor.split(Globals.AND).map(x => x.trim()),
    searchOr = searchingFor.split(Globals.OR).map(x => x.trim());
  var searchConstruct = {};
  searchConstruct['operator'] = searchAnd.length > 1 ? Globals.AND : searchOr.length > 1 ? Globals.OR : false;
  searchConstruct['terms'] =
    searchConstruct.operator == Globals.AND
      ? searchAnd
      : searchConstruct.operator == Globals.OR
      ? searchOr
      : [searchingFor];
  return searchConstruct;
}
