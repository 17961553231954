export default {
  ORIGINAL_GRID: new DocumentFragment(),
  BATCH_GRID: new DocumentFragment(),
  LOCKED_CANDIDATES: new DocumentFragment(),
  LOCKED_FILTERS: [],
  PUBLIC_IMAGE_DATA: [],
  PUBLIC_VIEW_STATS: {},
  PUBLIC_PARTY_META: {},
  PUBLIC_RSVP_SUMS: {},
  FEATURED_IMAGES: [],
  FILTER_POSITION: 'position',
  FILTER_TAG: 'tag',
  FILTER_PARTY: 'p_id',
  FILTER_REPLY: 'reply_n',
  FILTER_SHOWED: 'showed',
  FILTER_SEARCH: 'search',
  FILTER_SG_TRIGGERED: 'sg_triggered',
  INTERVALS: {},
  STATUS_SUCCESS: 1,
  STATUS_ERROR: 0,
  LOGICAL_REPLACE: 1000,
  LOGICAL_UNION: 2000,
  AND: '&&',
  OR: '||',
  POSITION: {
    top: {
      str: 'Top',
      form: 'wpcf-fields-checkboxes-option-977215a440eaf9bdaa3d31b97dd840e9-1',
      id: 'top'
    },
    'vers-top': {
      str: 'Vers Top',
      form: 'wpcf-fields-checkboxes-option-380353e0a8e91e02cb409517527b298b-1',
      id: 'vers-top'
    },
    vers: {
      str: 'Vers',
      form: 'wpcf-fields-checkboxes-option-ea46b0799153fc7bb146234a6a51841e-1',
      id: 'vers'
    },
    'vers-bottom': {
      str: 'Vers Bottom',
      form: 'wpcf-fields-checkboxes-option-7d80df1a9b419bf2084ce3b1fd84d27e-1',
      id: 'vers-bottom'
    },
    bottom: {
      str: 'Bottom',
      form: 'wpcf-fields-checkboxes-option-a6bf5e9fc961c14dc2e9c0ee7aeb7ad7-1',
      id: 'bottom'
    }
  },
  REPLY: {
    firm_yes: {
      int: 1,
      str: 'Firm Yes',
      public: 'Yes',
      style: 'success'
    },
    fresh_yes: {
      int: 3,
      str: 'Fresh Yes',
      public: 'Yes',
      style: 'success'
    },
    no: {
      int: 0,
      str: 'Firm No',
      public: 'No',
      style: 'danger'
    },
    maybe: {
      int: 4,
      str: 'Maybe',
      public: 'Maybe',
      style: 'info'
    },
    no_reply: {
      int: 5,
      str: 'No Reply',
      public: 'No Reply',
      style: 'default'
    },
    wait_list: {
      int: 6,
      str: 'Wait List',
      public: 'Wait List',
      style: 'warning'
    }
  },
  NOTIFICATION_STATUS: {
    initialized: {
      int: 0,
      str: 'Initialized',
      need: 'initial_invite',
      been: null
    },
    invited: {
      int: 1,
      str: 'Invited',
      need: 'reminder_yes_maybe',
      been: 'initial_invite'
    },
    reminded: {
      int: 2,
      str: 'Reminded',
      need: 'reminder_no_reply',
      been: 'initial_invite'
    },
    confirmed: {
      int: 3,
      str: 'Confirmed',
      need: 'room_number',
      been: 'initial_invite'
    },
    waitlisted: {
      int: 4,
      str: 'Un-Waitlisted',
      need: 'wait_list',
      been: 'invited'
    },
    roomed: {
      int: 5,
      str: 'Roomed',
      need: null,
      been: 'room_number'
    }
  },
  IMAGE_STATUS: {
    approved: {
      int: 0,
      str: 'Approved'
    },
    removed: {
      int: 1,
      str: 'Removed'
    },
    review: {
      int: 2,
      str: 'Under Review'
    },
    hidden: {
      int: 3,
      str: 'Hidden'
    }
  },
  PARTY_STATUS: {
    inactive: {
      int: 0,
      str: 'Inactive'
    },
    active: {
      int: 1,
      str: 'Active'
    },
    onice: {
      int: 2,
      str: 'On Ice'
    },
    firstclass: {
      int: 3,
      str: 'First Class'
    },
    partyover: {
      int: 4,
      str: 'Party Over'
    }
  },
  CONTACT_TYPES: [
    { value: 0, title: 'None' },
    { value: 'adam4adam', title: 'Adam4Adam' },
    { value: 'bbrt', title: 'BBRT' },
    { value: 'email', title: 'E-Mail' },
    { value: 'grindr', title: 'Grindr' },
    { value: 'kik', title: 'Kik' },
    { value: 'scruff', title: 'Scruff' },
    { value: 'snapchat', title: 'Snapchat' },
    { value: 'squirt', title: 'Squirt' },
    { value: 'twitter', title: 'Twitter' },
    { value: 'whatsapp', title: 'WhatsApp' }
  ],
  CONTENT_OFFSET: 36,
  ROUTES: {},
  STYLES: {},
  TIMERS: [],
  ENUM: {
    next: 0
  },
  ROUTER: null,
  ATTENDEE: {},
  AJAX_LOCKED: [],
  ATM_SEARCHABLE_DATA: []
};
