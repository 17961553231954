require( 'jquery.easing' );

import GA from '@lib/ga';
import listeners from '@lib/handlers/listeners.js';
import {
  fg_console,
  fg_error,
  hardReload,
  font_spy,
  log_google_analytics,
  bounceBack,
  fg_alert,
  fix_lowres_images
} from '@lib/shared/util';
import {
  faDom
} from '@lib/fontawesome';

export default function() {

  fg_console( 'common' );

  faDom.watch();

  font_spy(); // true init

  listeners();

  control_scrolling();

}

function control_scrolling() {
  $( '#fg-delegate' )
    .on( 'click', 'a[href*="#"]:not([href="#"]):not([href="#0"])', function(
      event ) {
      // Capture the click analytic
      if ( $( this )
        .is( '[href="#collapseInvite"]' ) ) {
        log_google_analytics( this, element => {
          return GA.dispatch( 'collapseInvite' );
        } );
      }
      // On-page links
      if (
        location.pathname.replace( /^\//, '' ) == this.pathname.replace(
          /^\//, '' ) &&
        location.hostname == this.hostname
      ) {
        // Figure out element to scroll to
        var target = $( this.hash );
        target = target.length ? target : $( '[name=' + this.hash.slice( 1 ) +
          ']' );
        // Does a scroll target exist?
        if ( target.length ) {
          // Only prevent default if animation is actually gonna
          // happen
          event.preventDefault();
          var top = target.offset().top;

          var change_focus = function() {
            // Callback after animation
            // Must change focus!
            var $target = $( target );
            $target.focus();
            if ( $target.is( ':focus' ) ) {
              // Checking if the target
              // was focused
              return false;
            } else {
              $target.attr( 'tabindex', '-1' ); // Adding tabindex
              // for elements not
              // focusable
              $target.focus(); // Set focus again
            }
          };
          bounceBack( top, 500 )
            .then( change_focus() );
        }
      }
    } );
}

window.fg_console = fg_console;
window.fg_error = fg_error;
window.hardReload = hardReload;

/** These are used inline and therefore needed in window **/
window.formatPhone = function( obj ) {
  var numbers = obj.value.replace( /\+1|\D/g, '' ),
    char = {
      0: '(',
      3: ') ',
      6: '-'
    };
  obj.value = '';
  var max = Math.min( 10, numbers.length );
  for ( var i = 0; i < max; i++ ) {
    obj.value += ( char[ i ] || '' ) + numbers[ i ];
  }
};

window.alert = function( msg, callback ) {
  fg_alert( msg, callback );
};

window.formatInt = function formatInt( obj, max ) {
  var numbers = obj.value.replace( /\D/g, '' );
  max = max || 10;
  obj.value = '';
  for ( var i = 0; i < numbers.length && i < max; i++ ) {
    if ( i < max ) obj.value += numbers[ i ];
  }
};

window.fli = fix_lowres_images;

// Likely going to deprecate this
window.trimInput = function( obj ) {
  var which = window.event.which;
  if ( which == 37 || which == 39 ) {
    return false;
  }
  var characters = obj.value.replace( /\s+/g, '' );
  obj.value = '';
  for ( var i = 0; i < characters.length; i++ ) {
    obj.value += characters[ i ];
  }
};
