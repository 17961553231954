import jQuery from 'jquery';
window.$ = window.jQuery = jQuery;

import '@src/index.scss';
import Router from '@src/util/Router.js';
import baseStyles from '@scss/template/_base.module.scss';
import Globals from '@lib/globals';
import { admin_logged_in } from '@lib/shared/util';

const routes = [
  'home',
  'party-orig',
  'launch',
  'reset',
  'user-error',
  'fg-privacy',
  'attendee-master',
  'party-guest-checklist',
  // your-party,
  'remove',
  'checkin',
  'bouncer',
  'error404'
];
const router = new Router(routes);

Globals.ROUTES = routes;
Globals.ROUTER = router;
Globals.STYLES = baseStyles;

if (admin_logged_in()) {
  (async () => {
    const admin_styles = await import( /* webpackChunkName "[request]" */ '@scss/template/_admin-shared.module.scss' );
    Globals.STYLES = {...Globals.STYLES, ...admin_styles};
    jQuery(document).ready(() => router.loadEvents());
  })();
} else {
  jQuery(document).ready(() => router.loadEvents());
}

