export const scrollToggleFunc = () => {

  const scrollToTopButton = document.getElementById('js-top');

  // Get the current scroll value
  let y = window.scrollY,
      thresh = window.innerHeight;

  // If the scroll value is greater than the window height, let's add a class to the scroll-to-top button to show it!
  $(scrollToTopButton)
    .toggleClass("show", y > thresh)
    .toggleClass("hide", y <= thresh);
};

export const goToTop = () => {
  // Let's set a variable for the number of pixels we are from the top of the document.
  const c = document.documentElement.scrollTop || document.body.scrollTop;

  // If that number is greater than 0, we'll scroll back to 0, or the top of the document.
  // We'll also animate that scroll with requestAnimationFrame:
  // https://developer.mozilla.org/en-US/docs/Web/API/window/requestAnimationFrame
  if (c > 1) {
    window.requestAnimationFrame(goToTop);
    // ScrollTo takes an x and a y coordinate.
    // Increase the '10' value to get a smoother/slower scroll!
    window.scrollTo(0, c - c / 5);
  }
};
