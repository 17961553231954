import * as util from '@lib/shared/util';
import Globals from '@lib/globals';
import { user_endpoint_init, after_atm_grid_detail_loads, update_launch_notify_buttons } from '@lib/shared/state';
import {
  update_elements_after_filter,
  original_grid,
  locked_candidates,
  reset_locks,
  update_rsvp_count
} from '@lib/shared/filtering';
import { RenderLazy } from '@lib/shared/render';
import { logout_user } from '@lib/handlers/handle_user_logout';
import Poll from '@lib/Poll';
import partyStatusIs from '@template/helpers/partyStatusIs';
import { segregate_waitlisters } from '@routes/party-orig';

export function ajax_entry_point(request_data, beforeSend, done, always, fail, request_method) {
  /*
   * TYPICAL PROTOTYPE
   *
   * ajax_entry_point({ action: '' }, function (xhr) { // beforeSend }, function
   * (response, status, data) { // done if (Globals.STATUS_SUCCESS == status) { } else { } },
   * function (response, textstatus, xhr) { // always }, function (jqXHR,
   * textStatus) { // fail });
   */

  always =
    always ||
    function() {
      return true;
    };
  fail =
    fail ||
    function(jqXHR, textStatus, errorThrown) {
      fg_error(request_data);
      fg_error(errorThrown, 'Error in ajax_entry_point');
      return false;
    };
  request_method = request_method || 'POST';

  beforeSend =
    beforeSend ||
    function() {
      return true;
    };

  if (!check_version_mismatch())
    throw new Error('Version Mismatch', { cause: rest_obj.response_codes.REST_VERSION_MISMATCH });
  if (isAjaxLocked()) {
    fg_error('Ajax is locked, processing terminated');
    return false;
  }
  if (!request_data) return util.fg_alert('Missing request data');
  if (!done) return util.fg_alert('Missing done implementation');

  // Always check for the correct party, unless already set or doesn't apply
  request_data['p_id'] = request_data['p_id'] || util.get_selected_party();
  if (ajax_obj.nonce) {
    request_data['_wpnonce'] = ajax_obj.nonce;
  }
  request_data['logged_in'] = util.admin_logged_in();

  const ajaxArgs = {
    url: ajax_obj.ajaxurl,
    method: request_method,
    data: request_data,
    dataType: 'json',
    beforeSend: function(xhr) {
      $('_.server-error').remove();
      beforeSend(xhr);
    }
  };

  $.ajax(ajaxArgs)
    .done(function(response, textStatus, jqXHR) {
      if (typeof response === 'undefined' || !response) {
        fg_console(response);
        fg_console(textStatus);
        fg_console(jqXHR);
        return util.fg_alert('Fatal error');
      }

      if (Globals.STATUS_ERROR == response.status) {
        if (response.data && response.data.server_error) {
          fg_error(response.data.server_error);
          if (!util.is_production()) {
            var printerror = [];
            response.data.server_error.forEach((itm, i) => {
              for (const [key, value] of Object.entries(itm)) {
                printerror.push(`${key}: ${value}`);
              }
            });
            var filldivs = printerror.join('</div><div>');
            $('#fg-delegate').prepend(
              `<code class="_fg-alert ${Globals.STYLES.fgAlert_primary} d-inline-block text-left _server-error mt-5"><div>${filldivs}</div></code>`
            );
            window.scrollTo(0, 0);
          }
        }
        if (response.data && response.data.kill_session) {
          return window.location.replace(response.data.redirect_link || '/');
        }
      }

      // Grab the nonce
      try {
        if (typeof response.data.nonce !== 'undefined') {
          ajax_obj.nonce = response.data.nonce;
        }
      } catch (e) {
        fg_error('Problem returning nonce data');
      }

      if (response.data.caches_used) {
        fg_console(response.data.caches_used);
      }

      done(response, response.status, response.data, textStatus, jqXHR);
    })
    .fail(function(jqXHR, textStatus, errorThrown) {
      fg_error(jqXHR.responseText);
      fg_error(errorThrown);
      fail(jqXHR, textStatus, errorThrown);
      console.trace();
    })
    .always(function(response, textStatus, jqXHR) {
      if (response.data && response.data.recent_image_approvals) {
        (async () => {
          try {
            await load_profile_images();
            user_endpoint_init();
            util.fg_alert('One or more of your image(s) has been approved');
            setTimeout(() => {
              load_grid(true);
            }, 2000);
          } catch (e) {
            fg_error(e);
          }
        })();
      }
      if (response.data && response.data.rsvp_note_unread) {
        load_note_history(true);
      }
      always(response, textStatus, jqXHR);

      // This must go in always so the ajax lock is free and clear
      fg_console(`Loaded AJAX_ENTRY: ${JSON.stringify(ajaxArgs)} with response:`, response);
    });
}

export function isAjaxLocked() {
  // To override ajax lock, you need to add the class _ajax-override to the triggering element and
  // then when triggered, add the attribute data-override-target to fg-delegate which is checked here
  // Remove the attribute after event handling work is done
  var override = false;
  var override_target = $('#fg-delegate').attr('data-override-target');
  if (override_target && $(override_target).hasClass('_ajax-override')) {
    override = true;
  }
  var isLocked = Globals.AJAX_LOCKED.length > 0 && override === false;
  if (isLocked) {
    fg_console('Ajax Lock Status', Globals.AJAX_LOCKED);
  }
  return isLocked;
}

export function lockAjax(why) {
  if (Globals.AJAX_LOCKED.includes(why)) {
    fg_console('Ajax Already Locked', Globals.AJAX_LOCKED);
    return;
  }
  Globals.AJAX_LOCKED.push(why);
  fg_console('Ajax Locked', Globals.AJAX_LOCKED);
}

export function unlockAjax(why) {
  const index = Globals.AJAX_LOCKED.indexOf(why);
  if (index > -1) {
    Globals.AJAX_LOCKED.splice(index, 1);
  }
  fg_console('Ajax unLocked', Globals.AJAX_LOCKED);
}

export function ajaxPromise(request_data, request_method) {
  return new Promise((resolve, reject) => {
    ajax_entry_point(
      request_data,
      // Before Send
      () => {
        lockAjax(request_data.action);
      },
      // Done
      (response, status, data) => {
        unlockAjax(request_data.action);
        resolve(response);
      },
      // Always - if you need always, just add finally to the downstream try catch
      null,
      // Fail
      (jqXHR, textStatus) => {
        fg_error(jqXHR, textStatus);
        reject(new Error('Rejected Promise in ajaxPromise()'));
      },
      request_method
    );
  }).catch(err => {
    fg_error(err.message || err);
    if (err.cause) {
      util.clearTimers();
      handle_rest_rejections({ code: err.cause, message: err.message });
    }
    return Promise.reject(err.message || err);
  });
}

/**
 * This gets called BEFORE sending an AJAX request
 */
export function check_version_mismatch() {
  var valid_page = window.location.href.match(/(party-orig|your-party|launch|attendee-master)/);
  if (!valid_page) {
    return true;
  }
  const DOMVersion = $('body').attr('data-version'),
    cookieVersion = util.get_cookie('_fgl_release');

  return DOMVersion == cookieVersion;
}

export function rest_entry_point(route, icon, beforeSend, always) {
  var beforeSend =
      beforeSend ||
      function() {
        return true;
      },
    always =
      always ||
      function() {
        return true;
      },
    icon = icon || false;

  const ajaxPromiseFunc = (resolve, reject) => {
    if (!check_version_mismatch()) {
      reject({
        code: rest_obj.response_codes.REST_VERSION_MISMATCH,
        message:
          'We made some updates to the web site. Page will reload automatically in (10) seconds. If you were saving, try again after page reloads'
      });
    }

    if (isAjaxLocked()) {
      reject({
        code: rest_obj.response_codes.REST_AJAX_LOCKED,
        message: 'Preventing rest_entry_point because ajax is locked'
      });
    }

    // If criticalcss, pass along the qs
    let qs = util.getQueryString('criticalcss');
    if (qs && qs == 2868) {
      if (route && route.indexOf('?') > -1) {
        route += '&';
      } else {
        route += '?';
      }
      route += 'criticalcss=2868';
    }

    const request = $.ajax({
      url: rest_obj.resturl + route,
      contentType: 'application/json',
      method: 'GET',
      dataType: 'json',
      beforeSend: function(xhr) {
        if (rest_obj.nonce) {
          xhr.setRequestHeader('X-WP-Nonce', rest_obj.nonce);
        }
        beforeSend(xhr);
      }
    });

    request
      .done(response => {
        resolve({
          response: response
        });
      })
      .fail((jqXHR, textStatus) => {
        let responseJSON = jqXHR.responseJSON || {},
          responseText = jqXHR.responseText || '';
        if (responseJSON && responseJSON.code) {
          reject(responseJSON);
        }
      })
      .always(response => {
        fg_console(`Loaded REST route: ${route} with response:`, response);
        always(response);
      });
  };

  return new Promise(ajaxPromiseFunc).catch(err => {
    handle_rest_rejections(err);
    return Promise.reject(err); // This halts all further then() processing AS LONG AS they propagate the rejection the same way!!
  });
}

/**
 *   You may fabricate the responseJSON to a raw object (like version mismatch)
 */
export function handle_rest_rejections(responseJSON, urlOverride) {
  // Change presentation of error if cookie is present or not production
  var fg_debug = false;
  try {
    fg_debug = document.cookie.split(';').filter(item => item.trim().startsWith('_fgl_debug=')).length;
  } catch (e) {}
  fg_debug = fg_debug || !util.is_production();

  const codes = rest_obj.response_codes,
    errorPresentation = {
      debug: JSON.stringify(responseJSON, null, 2), // admin message to present / log
      user: 'Error rendering this page. Try refreshing', // default user friendly message to present, override below
      callback: null, // fn to call just before we exit out
      alert_callback: null, // fn to call after user is presented with alert
      alert_delay: null, // defaults to none, seconds if needed
      suppress: false, // set to suppress popup alert to user
      clear_timers: true,
      error: new Error('REST rendering error, see responseJSON') // set to an Error object to trigger admin email, to suppress, override to null below
    };

  // If you want to
  switch (responseJSON.code) {
    case codes.REST_VERSION_MISMATCH:
      errorPresentation.user = 'Page will refresh in (10) seconds because we updated our software';
      errorPresentation.alert_callback = modal => {
        window.hardReload(urlOverride);
      };
      errorPresentation.alert_delay = 10000;
      errorPresentation.error = null;
      break;
    case codes.REST_RENDERING_ERROR:
      break;
    case codes.REST_FORBIDDEN:
    case codes.REST_INVALID_NONCE:
      errorPresentation.user = 'Error accessing the server';
      errorPresentation.alert_callback = () => {
        logout_user(errorPresentation.user);
      };
      errorPresentation.alert_delay = 10000;
      errorPresentation.error = null;
      break;
    case codes.REST_NO_ROUTE:
      errorPresentation.user = 'Page will refresh in (10) seconds because we updated our software';
      errorPresentation.alert_callback = modal => {
        window.hardReload(urlOverride);
      };
      errorPresentation.alert_delay = 10000;
      errorPresentation.error = null; // No admin notification required
      break;
    case codes.REST_AUTH_FAILED:
      errorPresentation.user = 'Your credentials are no longer valid and you will need to log back in';
      errorPresentation.alert_callback = () => {
        logout_user(errorPresentation.user);
      };
      errorPresentation.alert_delay = 10000;
      errorPresentation.error = null; // No admin notification required
      break;
    case codes.REST_AUTH_PARTY:
      break;
    case codes.REST_TIMEOUT:
      errorPresentation.user = 'Your session has expired and you will need to log back in';
      errorPresentation.alert_callback = () => {
        logout_user(errorPresentation.user);
      };
      errorPresentation.alert_delay = 10000;
      errorPresentation.error = null; // No admin notification required
      break;
    case codes.REST_NO_DATA:
      errorPresentation.clear_timers = false;
      break;
    case codes.REST_AJAX_LOCKED:
      errorPresentation.suppress = true;
      errorPresentation.clear_timers = false;
      break;
    default:
      errorPresentation.user = 'Session has become out of sync. Page will refresh in (10) seconds';
      errorPresentation.alert_callback = modal => {
        window.hardReload(urlOverride);
      };
      errorPresentation.alert_delay = 10000;
  }

  const presentedMessage =
      errorPresentation.user +
      (fg_debug ? ` [${responseJSON.code}]<pre class="text-left">${errorPresentation.debug}</pre>` : ''),
    callback = errorPresentation.callback || false,
    alert_callback = errorPresentation.alert_callback || false,
    timer = fg_debug ? false : errorPresentation.alert_delay; // no timers for debugging

  if (!errorPresentation.suppress) {
    util.fg_alert(presentedMessage, alert_callback || function() {}, {}, timer);
  }

  if (errorPresentation.clear_timers) {
    util.clearTimers();
  }

  if (callback && callback instanceof Function) {
    callback();
  }

  if (errorPresentation.err && errorPresentation.err instanceof Error) {
    return fg_error(err, responseJSON);
  }

  fg_error(responseJSON);
}

export async function load_grid(force_refresh) {
  var no_pics = util.get_pic_count() == 0,
    partyOnIce = partyStatusIs('onice'),
    firstClassUser = Globals.ATTENDEE.rating > 3,
    consideredOnIce = partyOnIce || (partyStatusIs('firstclass') && firstClassUser === false);

  if (!util.admin_logged_in() && no_pics) {
    return Promise.resolve({});
  }

  var needsReloading =
    force_refresh === true || partyStatusIs('active') || (partyStatusIs('firstclass') && firstClassUser === true);

  if (!needsReloading) {
    return Promise.resolve({});
  }

  let chunks = $('body').hasClass('attendee-master')
    ? await get_pageable_chunks({ table: 'vw_attendee_master_nothumbnail' })
    : await get_pageable_chunks({ table: 'vw_all_affirmative_rsvps_for_all_parties', p_id: util.get_selected_party() });

  const endpoints = {
    'attendee-master': {
      template: 'attendee-master/atm-grid',
      endpoint: chunks.chunks.map(
        last_seen_id =>
          'ui-attendee-master-grid/?' +
          util.httpBuildQuery({
            last_seen_id: last_seen_id,
            namespace: 'atm_grid',
            batch_size: chunks.limit
          })
      ),
      parent: '#atmGridData',
      chunkSort: 'a_id'
    },
    'party-orig': {
      template: consideredOnIce === false ? 'partials/public-grid' : 'partials/tiny-image-grid',
      endpoint:
        consideredOnIce === false
          ? chunks.chunks.map(
              last_seen_id =>
                'ui-public-grid/?' +
                util.httpBuildQuery({
                  p_id: util.get_selected_party(),
                  namespace: 'public_grid',
                  batch_size: chunks.limit,
                  image_size: 'small-full',
                  last_seen_id: last_seen_id
                })
            )
          : 'ui-picture-engine/?' +
            util.httpBuildQuery({
              namespace: 'tiny_image_grid',
              exclude_faces: true,
              image_size: 'small',
              filter_image_status: [Globals.IMAGE_STATUS.approved.int],
              random_limit: 100
            }),
      parent: '#fg-main-grid',
      chunkSort: 'modified',
      fn_data_transform: segregate_waitlisters
    }
  };

  const endpoint = Object.keys(endpoints).filter(e => $(`body.${e}`).length);

  if (endpoint[0] == 'party-orig') {
    $('#fg-main-grid')
      .addClass(Globals.STYLES.mainGridProgress)
      .find('._fg-grid-wrapper')
      .addClass('animated fadeOut)');
  } else if (endpoint[0] == 'attendee-master') {
    $('#atmGridData').empty();
  } else {
    // No grid to refresh
    return;
  }

  let config = {
    templatePath: endpoints[endpoint].template,
    context: [endpoints[endpoint].endpoint],
    node: endpoints[endpoint].parent,
    append: endpoint[0] == 'attendee-master',
    chunkSort: endpoints[endpoint].chunkSort,
    fn_data_transform: endpoints[endpoint].fn_data_transform
  };

  return RenderLazy(config)
    .then(lastContext => {
      // Empty original_grid
      let og = $('#fg-main-grid ._grid__brick').get();
      original_grid(og);
      if ($('body.party-orig')) {
        locked_candidates(og);
      }
      return lastContext;
    })
    .then(lastContext => {
      const fakeThis = {},
        callback = () => {
          $('#fg-main-grid > ._fg-grid-wrapper')
            .removeClass('fadeOut')
            .addClass('fadeIn');
          reset_locks();
          $('#_checkTiny').prop('checked', false);
          util.fix_lowres_images('#fg-main-grid ._fg-image-wrap');
          $('#fg-main-grid')
            .removeClass(Globals.STYLES.mainGridProgress)
            .addClass(Globals.STYLES.mainGrid);
          Globals.PUBLIC_IMAGE_DATA = lastContext.response.public_grid;
          Globals.ATM_SEARCHABLE_DATA = lastContext.response.atm_grid
            ? lastContext.response.atm_grid.map(rec => rec.searchable_terms)
            : [];
          update_elements_after_filter();
          $('#_fg-search-box')
            .add('._atm-buttons .btn,._atm-buttons input,._fg-filter .btn')
            .removeClass('disabled');
        };

      // Must update the rsvp counts in the time-to-reload, so this muct be async
      return (async () => {
        callback();
        // Public always begins fully locked
        await reload_rsvp_counts();
        $('#_time-to-reload').attr('data-last-count', util.get_rsvp_count());
        return lastContext;
      })();
    });
}

export function load_profile_images() {
  // Lazy load the image-management template
  const endpoint_data = {
    a_id: util.get_current_user() || $('#_fg-attendee-detail').attr('data-attendee-id'),
    namespace: 'image_management',
    exclude_faces: false,
    exclude_removed: true,
    image_size: 'small-full'
  };
  if (!util.admin_logged_in()) {
    endpoint_data['filter_image_status'] = [
      Globals.IMAGE_STATUS.approved.int,
      Globals.IMAGE_STATUS.review.int,
      Globals.IMAGE_STATUS.hidden.int
    ];
  }
  const endpoint = 'ui-picture-engine/?' + util.httpBuildQuery(endpoint_data);

  let config = {
    templatePath: 'partials/image-management',
    context: endpoint,
    node: $('#_profile-images')
  };

  return RenderLazy(config);
}

export function load_note_history(unreadMessage) {
  var r_id = util.get_selected_r_id();
  // Reload the note history

  let config = {
    templatePath: 'partials/chat-rsvp-note-container',
    context:
      'ui-note-history/?' +
      util.httpBuildQuery({
        a_id: util.get_current_user() || $('#_fg-attendee-detail').attr('data-attendee-id'),
        p_id: util.get_selected_party(),
        namespace: 'rsvp_notes'
      }),
    node: $(`#_noteHistory-${r_id}`)
  };

  return RenderLazy(config)
    .then(context => {
      const container = $(`#_chatContainer-${r_id}`);
      if (container.length) {
        container[0].scrollTop = container[0].scrollHeight;
      }
    })
    .then(() => {
      // Indicate there's a new message
      if (unreadMessage) {
        return util.fg_alert('New DM from Parker under the RSVP tab');
      }
    });
}

export function reload_attendee_essentials() {
  return rest_entry_point(
    'ui-misc/?' +
      util.httpBuildQuery({
        a_id: util.get_current_user(),
        namespace: 'attendee_essentials'
      })
  )
    .then(response => {
      Globals.ATTENDEE = response.response.attendee_essentials;
      fg_console('GLOBALS', Globals);
      return response;
    })
    .catch(error => {
      return Promise.reject(error);
    });
}

export function reload_party_meta() {
  return rest_entry_point('party')
    .then(response => {
      Globals.PUBLIC_PARTY_META = response.response;
      return response;
    })
    .catch(error => {
      return Promise.reject(error);
    });
}

export function reload_parties_attended() {
  return rest_entry_point('parties')
    .then(response => {
      ajax_obj.parties_attended = response.response;
      return response;
    })
    .catch(error => {
      return Promise.reject(error);
    });
}

export function reload_rsvp_counts() {
  return rest_entry_point('rsvp-counts')
    .then(response => {
      Globals.PUBLIC_RSVP_SUMS = response.response;
      return response;
    })
    .then(response => {
      update_rsvp_count(true);
      return response;
    })
    .catch(error => {
      return Promise.reject(error);
    });
}

export function reload_featured_images() {
  return rest_entry_point(
    'ui-picture-engine/?' +
      util.httpBuildQuery({
        namespace: 'picture_data',
        is_featured_image: true,
        image_size: 'small-full'
      })
  )
    .then(response => {
      Globals.FEATURED_IMAGES = response.response.picture_data;
    })
    .catch(error => {
      return Promise.reject(error);
    });
}

export function get_pageable_chunks(args) {
  let { table, column, chunk_size, order, p_id } = args;
  return rest_entry_point(
    'pageable-chunks/?' +
      util.httpBuildQuery({
        table: table || 'vw_attendee_master_nothumbnail',
        column: column || 'a_id',
        order_by: order || 'DESC',
        p_id: p_id || ''
      })
  )
    .then(response => {
      let chunks = response.response.chunks,
        limit = response.response.limit;
      return response.response;
    })
    .catch(error => {
      return Promise.reject(error);
    });
}

export function log_analytic(event_name, event_value) {
  var is_admin = util.admin_logged_in(),
    data = {
      a_id: util.get_current_user(),
      event_name: event_name,
      event_value: event_value
    };
  if (is_admin) {
    return;
  }
  return rest_entry_point('log-analytic/?' + util.httpBuildQuery(data))
    .then(response => {
      fg_console('Logged analytic', data, response.response);
      return response;
    })
    .catch(error => {
      return Promise.reject(error);
    });
}

/** Mirrors the fg_updated_user_metadata so we can keep REST objects up to date **/
export function updated_user_metadata(args) {
  // Comes in as an array of argument objects which can be parsed further at a later date
  // For now, just checking on post_type
  if (!args || !args.length) {
    return;
  }
  var post_types = args.map(arg_record => arg_record.post_type),
    hashes = args.map(arg_record => arg_record.hash),
    unique_post_types = [...new Set(post_types)],
    processed_hashes = $('#fg-delegate').attr('data-meta-updates'),
    processed_hashes = (processed_hashes && processed_hashes.split(',')) || [];

  // Filter out processed hashes
  var unprocessed_hashes = hashes.filter(hash => !processed_hashes.includes(hash));

  if (!unprocessed_hashes.length) {
    return;
  }

  unique_post_types.forEach((post_type, i) => {
    switch (post_type) {
      case 'attendee':
        args.some(update_obj => {
          if (update_obj.meta_key == '_thumbnail_id' || update_obj.meta_key == 'featured_image_id') {
            reload_featured_images();
            return true;
          }
          return false;
        });
        $('#fg-delegate').attr('data-meta-updates', processed_hashes.concat(unprocessed_hashes).join(','));
        break;
      case 'rsvp':
        reload_parties_attended()
          .then(reload_rsvp_counts())
          .then(() => {
            $('#fg-delegate').attr('data-meta-updates', processed_hashes.concat(unprocessed_hashes).join(','));
          });
        fg_console('parties_attended and party_meta refreshed');
        break;
    }
  });
}
export function heartbeatAdmin() {
  const hba = new Poll({
    fn: () => rest_entry_point('heartbeat-admin'),
    callback: response => {
      var data = response.response.data || false;
      // Handle any data refreshes based on updated data from server
      if (data && data.fg_updated_user_metadata) {
        fg_console(data.fg_updated_user_metadata);
        updated_user_metadata(data.fg_updated_user_metadata);
      }
      $('body').data('admin-token', parseInt(new Date().getTime()));
    },
    interval: 20000
  }).poll('heartbeatAdmin');
}
