import Globals from '@lib/globals';
import { rest_entry_point, isAjaxLocked } from '@lib/shared/ajax';

export default class {
  constructor({ fn, callback, interval }) {
    this.fn = fn;
    this.callback = callback;
    this.interval = interval || 30000;
    this.noop = () => {};
    this.browserPrefixes = ['moz', 'ms', 'o', 'webkit'];
    this.isVisible = true; // internal flag, defaults to true

    // bind and handle events
    var browserPrefix = this.getBrowserPrefix(),
      hiddenPropertyName = getHiddenPropertyName(browserPrefix),
      visibilityEventName = this.getVisibilityEvent(browserPrefix);

    document.addEventListener(visibilityEventName, e => this.handleVisibilityChange, false);
    document.addEventListener('focus', e => this.handleVisibilityChange(true), false);
    document.addEventListener('blur', e => this.handleVisibilityChange(false), false);
    window.addEventListener('focus', e => this.handleVisibilityChange(true), false);
    window.addEventListener('blur', e => this.handleVisibilityChange(false), false);
  }
  getBrowserPrefix() {
    for (var i = 0; i < this.browserPrefixes.length; i++) {
      if (getHiddenPropertyName(this.browserPrefixes[i]) in document) {
        // return vendor prefix
        return this.browserPrefixes[i];
      }
    }

    // no vendor prefix needed
    return null;
  }
  onVisible() {
    // prevent double execution
    if (this.isVisible) {
      return;
    }

    // change flag value
    this.isVisible = true;
  }
  onHidden() {
    // prevent double execution
    if (!this.isVisible) {
      return;
    }

    // change flag value
    this.isVisible = false;

    fg_console('~~~~~~~~~~~~~ Paused ~~~~~~~~~~~~~');
  }
  poll(name) {
    (async function executePoll(thiz) {
      const hidden = thiz.isHidden(),
            ajaxLocked = isAjaxLocked(),
            pausePoll = hidden || ajaxLocked;
      try {
        if ( pausePoll ) {
          if ( ajaxLocked ) {
            fg_error("Possible collision with Poll and ajax_entry_point");
          }
          await thiz.noop();
        } else {
          const response = await thiz.fn();
          if (thiz.callback) thiz.callback(response);
        }
        const tid = setTimeout(executePoll, pausePoll ? 5000 : thiz.interval, thiz);
        Globals.TIMERS.push(tid);
      } catch (error) {
        fg_console(`Broke out of ${name}`);
        fg_error(error);
      }
    })(this);
  }
  handleVisibilityChange(forcedFlag) {
    // forcedFlag is a boolean when this event handler is triggered by a
    // focus or blur eventotherwise it's an Event object
    if (typeof forcedFlag === 'boolean') {
      if (forcedFlag) {
        return this.onVisible();
      }

      return this.onHidden();
    }

    if (document[hiddenPropertyName]) {
      return this.onHidden();
    }

    return this.onVisible();
  }
  getVisibilityEvent(prefix) {
    return (prefix ? prefix : '') + 'visibilitychange';
  }
  isHidden() {
    return !this.isVisible;
  }
}

function getHiddenPropertyName(prefix) {
  return prefix ? prefix + 'Hidden' : 'hidden';
}
