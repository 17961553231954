class PartyState {
  constructor() {
    if (!PartyState.instance) {
      this.setItem('current_party', ajax_obj.current_party || null);
      this.setItem('selected_party', null);
      PartyState.instance = this;
    }
    return PartyState.instance;
  }
  // There is one place and one place only that stores the current and selected party.
  // Selected party should always be checked.
  // Current party is private and will be returned as the standard fallback
  get_selected_party() {
    let selected_party = this.getItem('selected_party'),
      current_party = this.get_current_party();
    return selected_party || current_party || ajax_obj.current_party;
  }
  set_selected_party(p_id) {
    if (!p_id) {
      this.removeItem('selected_party');
      $('#_selectedParty').attr('data-selected-party', '');
      return;
    }
    this.setItem('selected_party', p_id);
    $('#_selectedParty').attr('data-selected-party', p_id);
  }
  get_current_party() {
    return this.getItem('current_party');
  }
  set_current_party(p_id) {
    this.setItem('current_party', p_id);
    $('#_selectedParty').attr('data-current-party', p_id || '');
  }
  clear() {
    this.removeItem('selected_party');
    this.removeItem('current_party');
    $('#_selectedParty')
      .attr('data-selected-party', '')
      .attr('data-current-party', '');
  }
  getItem(key) {
    let val = parseInt(this[key]);
    if (val) {
      return this[key];
    }
    return null;
  }
  setItem(key, value) {
    let checked = parseInt(value);
    this[key] = checked || null;
  }
  removeItem(key) {
    this[key] = null;
  }
}

const instance = new PartyState();

window.PartyState = instance;

export default instance;
