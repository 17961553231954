import Globals from '@lib/globals';
import { handler_dispatch } from '@lib/handlers/listeners';
import scrollTo from '@lib/shared/scrollTo';
import { rest_entry_point, reload_party_meta } from '@lib/shared/ajax';
import { update_rsvp_count } from '@lib/shared/filtering';
import { adjust_page_for_global_notice } from '@lib/shared/state';
import Navigator from '@lib/navigator';
import loadModule from '@lib/shared/loadModule';
import PartyStateInstance from '@lib/PartyState';

export function admin_logged_in() {
  const token = $('body').data('admin-token') || ajax_obj.admin_token, // put there once
    logged_in = $('body').hasClass('logged-in');

  // heartbeatAdmin sets the time with each heartbeat. Since it's only accessible from admin,
  // that in combination with a 5 minute window should offer some secure fallback
  return logged_in && parseInt(new Date().getTime() / 1000) - token < 60 * 5;
}

export function getModuleName() {
  const bodyClasses = $('body')
      .attr('class')
      .split(' ')
      .map(theClass => theClass.replace('-js', '')),
    route = bodyClasses.filter(value => Globals.ROUTES.includes(value));
  if (Array.from(new Set(route)).length > 1) {
    throw 'Invalid route config';
  }
  return route[0] || 'default';
}

export function handlePromiseErr(error) {
  fg_console( 'handlePromiseErr', error );
  if (error.name == 'ChunkLoadError') {
    try {
      fg_alert('Web site has been updated since you last visited. Browser will reload', () => {
        window.hardReload();
      });
    } catch (e) {
        fg_error( e );
    }
  } else {
    fg_error(error, 'Error in: ' + getModuleName());
  }
}

export function get_party_meta(p_id, key) {
  const getPartyMeta = p_meta => {
    if (!p_id) {
      return p_meta;
    }
    const party_obj = p_meta[p_id];
    if (!party_obj || !Object.keys(party_obj).length) {
      throw new Error('Cannot find party meta for p_id: ' + p_id);
    }
    if (!key) {
      return party_obj;
    }
    const data = party_obj[key];
    if (!data) {
      return false;
    }
    return data;
  };

  try {
    let party_meta = Globals.PUBLIC_PARTY_META;
    if (!Object.keys(party_meta).length) {
      return false;
    }
    return getPartyMeta(party_meta);
  } catch (e) {
    fg_error(e);
  }
  return false;
}

export function addImageProcess(src) {
  return new Promise((resolve, reject) => {
    if (src.complete) {
      resolve(src);
    } else {
      var image_element = new Image();
      image_element.onload = () => {
        resolve(image_element);
      };
      image_element.onerror = reject;
      image_element.src = src;
    }
  });
}

export function preload_enlargeable_images(parent) {
  var sources = parent
    .find('picture')
    .get()
    .filter(pic => $(pic).attr('data-full-image') !== undefined)
    .map(image => ajax_obj.upload_url + '/' + $(image).attr('data-full-image'));

  // Run it but don't hold up anything
  (async all_images => {
    try {
      await Promise.all(
        all_images.map(async source => {
          if (source) await addImageProcess(source);
        })
      );
    } catch (e) {
      fg_error(e.message);
    }
    fg_console('Done preprocessing all enlargeable images');
  })(sources);

  fg_console('Kicking off preprocessing of all enlargeable images');
}

export function get_rsvp_icon(reply_n, a_id) {
  a_id = parseInt(a_id);
  reply_n = parseInt(reply_n);
  var rsvp_indicator = [],
    waitlistRank = () => {
      var p_id = get_selected_party();
      const rank = () => {
        return ajax_obj.parties_attended
          .filter(rec => rec.p_id == p_id && rec.reply_n == Globals.REPLY.wait_list.int)
          .sort((a, b) => a.rsvp_t - b.rsvp_t)
          .map(elem => elem.a_id)
          .indexOf(a_id) + 1;
      };
      return rank();
    };

  switch (parseInt(reply_n)) {
    case Globals.REPLY.fresh_yes.int:
    case Globals.REPLY.firm_yes.int:
      rsvp_indicator = ['text-success', 'fas', 'fa-check-circle'];
      break;
    case Globals.REPLY.no.int:
      rsvp_indicator = ['text-danger', 'far', 'fa-times-circle'];
      break;
    case Globals.REPLY.maybe.int:
      rsvp_indicator = ['text-default', 'fas', 'fa-question-circle'];
      break;
    case Globals.REPLY.wait_list.int:
      rsvp_indicator = ['text-warning', 'far', 'fa-hourglass'];
      break;
    default:
      rsvp_indicator = [];
  }
  let classes = rsvp_indicator.join(' '),
    rsvp_icon = `<i class="${classes}"></i>`,
    waitlist_rank = reply_n == Globals.REPLY.wait_list.int ? waitlistRank() : 0,
    waitlist_rank_icon =
      waitlist_rank > 0 ? `<span class="${Globals.STYLES.waitlistRank}">${waitlist_rank}</span>` : '';
  return rsvp_indicator.length < 1
    ? ''
    : `<span class="_rsvp-indicator ${Globals.STYLES.rsvpIndicator} ${Globals.STYLES.flexGap2}">
      ${rsvp_icon}
      ${waitlist_rank_icon}
    </span>`;
}

export function menuOffset() {
  let offset = Globals.CONTENT_OFFSET + alertOffset(); // How much space to leave from menu (or alert) to heading
  let finalOffset = 0;

  if ($(`._fg-public-menu svg`).length) {
    return $('._fg-public-menu').get(0).offsetHeight + offset;
  }

  return new Promise(resolve => {
    observeRendering($('html').get(0), (mutationsList, observer) => {
      for (const mutation of mutationsList) {
        if (mutation.type === 'attributes') {
          let classList = [...mutation.target.classList];
          if (
            mutation.attributeName == 'class' &&
            mutation.target.classList &&
            classList.includes('fontawesome-i2svg-complete')
          ) {
            if ($(`._fg-public-menu svg`).length) {
              finalOffset = $('._fg-public-menu').get(0).offsetHeight + offset;
              resolve(finalOffset);
            }
          }
        }
      }
    });
  });
}

export function alertOffset() {
  if ($('#_selectedParty._first-class').length || $('#_selectedParty._emergency-alert').length) {
    return $('._global-notice').get(0).offsetHeight;
  }
  return 0;
}

export async function fix_lowres_images() {
  // I don't think this function is necessary anymore. Keep until you know for sure.
  return;
  var target = $('._fli'); // _fli indicates needs fixing
  const images = target.find('img'),
    calc_max_dims = which => {
      var track = {};
      for (var i = 0; i < images.length; i++) {
        var img = images[i];
        var wh = which == 'w' ? $(img).width() : $(img).height();
        if (track[wh]) {
          track[wh]++;
          if (track[wh] > Math.min(images.length, 10)) {
            return wh;
          }
        } else {
          track[wh] = 1;
        }
      }
    },
    fix_image = image => {
      var maxWidth = calc_max_dims('w'),
        maxHeight = calc_max_dims('h'),
        naturalWidth = image.naturalWidth,
        naturalHeight = image.naturalHeight,
        ratio = naturalWidth / naturalHeight,
        src = $(image).attr('src'),
        src_short = src.match(/uploads\/(.*)$/)[1];
      fg_console( ratio, src_short);
      if (ratio < 0.5) {
        fg_console('Fixed maxHeight on ' + src_short);
        maxHeight = maxHeight == 0 ? 'initial' : maxHeight + 'px';
        $(image).css('max-height', maxHeight);
        let a_id = $(image)
          .parents('[data-attendee-id]')
          .attr('data-attendee-id');
        syncToOriginalGrid(a_id, maxHeight);
      }
    };

  function loadImage(elem) {
    return new Promise((resolve, reject) => {
      if (elem.complete) {
        resolve(elem);
      } else {
        elem.onload = () => resolve(elem);
      }
      elem.onerror = reject;
    });
  }

  function syncToOriginalGrid(a_id, maxHeight) {
    let found_attendee = Array.from(Globals.ORIGINAL_GRID.children).find(grid_element => {
      return (
        $(grid_element)
          .find('._fg-modal-on-demand')
          .data('attendee-id') == a_id
      );
    });
    $(found_attendee)
      .find('img')
      .css('max-height', maxHeight);
  }

  await Promise.all(
    images.get().map(async image => {
      const resolvedImage = await loadImage(image);
      fix_image(resolvedImage);
    })
  );
}

export function control_history(next, type, template) {
  if (!next) return;
  var re = /_fg-public-section-/,
    fragment = '',
    url = next;

  const state_obj = {
    orig_url: next,
    fragment: fragment,
    type: type,
    template: template
  };

  // Party Orig menu tabs
  if (next && next.toString().match(re)) {
    let fragment = '#' + next.toString().replace(re, '');
    state_obj.fragment = fragment;
    url = fragment;
  }

  history.pushState(state_obj, '', url);

  fg_console('PUSHED', state_obj);

}

export async function copy_emails() {
  var ClipboardJS = await loadModule( 'clipboard' );
  var clipboard = new ClipboardJS.default('._copy-emails');
  clipboard.on('success', function(e) {
    var clicked = $(e.trigger);
    animateCSS(clicked, 'fadeInUp faster').then(message => {
      clicked.html('Copied');
    });
    e.clearSelection();
  });
}

/** Helper utility to scroll to text after and before collapsing **/
export function collapse_scroll(element, offset, focus_target) {
  var offset = offset || 50,
    offset = is_mobile() ? offset + 30 : offset,
    handle_collapse = function(event) {
      var current_scroll = window.scrollY,
        is_collapsing = !$(this).hasClass('show'),
        original_scroll = $(element).attr('data-scroll') || current_scroll;
      if (!is_collapsing) {
        $(element).attr('data-scroll', current_scroll);
      }
      var scroll = !is_collapsing ? $(this).offset().top - offset : original_scroll;
      bounceBack(scroll, 500);
      if (focus_target) {
        $(focus_target)
          .get(0)
          .focus();
      }
    };
  $(element)
    .on('hidden.bs.collapse', handle_collapse)
    .on('shown.bs.collapse', handle_collapse);
}

/** USE THIS SPARINGLY! = I might want to get rid of it, plus you may need to remove prefixes **/
export function animateCSS(element, animation) {
  const animationName = animation;
  const node = $(element);
  var resolved = false;

  // We create a Promise and return it
  const animationPromise = (resolve, reject) => {
    node.addClass('animated ' + animationName);

    // When the animation ends, we clean the classes and resolve the Promise
    function handleAnimationEnd(event) {
      event.stopPropagation();
      node.removeClass('animated ' + animationName);
      resolved = true;
      resolve(node);
    }

    // Fallback in case animation doesn't trigger, we resolve the promise
    setTimeout(() => {
      if ( ! resolved ) resolve(node);
    }, 1500);

    node[0].addEventListener('animationend', handleAnimationEnd, { once: true });
  };
  return new Promise(animationPromise);
}

export function timestamp() {
  var tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
  var localISOTime = new Date(Date.now() - tzoffset).toISOString();
  return localISOTime.replace(/z|t/gi, ' ').trim();
}

/** TODO: Needs to align with GA4 **/
export function log_google_analytics(element, f_event) {
  var event_obj = [];

  if (f_event && f_event instanceof Function) {
    event_obj = f_event(element);
  }
  if (event_obj) {
    event_obj['page_virtual'] = get_current_user()
      ? '/party-orig/logged-in/' + get_current_user()
      : event_obj.page_virtual || window.location.pathname;
    try {
      var p_id = get_selected_party(),
        party = get_party_meta(p_id, 'party_date_human') || 'No Party',
        gtag_data_base = {
          page_path: location.pathname,
          page_title: document.title,
          timestamp: timestamp(),
          page_location: location.href,
          a_id: get_current_user() || null,
          p_id: p_id || '0',
          party: party
        };

      var gtag_obj = { ...gtag_data_base, ...event_obj };

      fg_console('GA Analytics Object', gtag_obj);

      if (!is_production() || typeof gtag !== 'function') {
        throw 'gtag disabled, skipping all analytics';
      }

      gtag('event', event_obj.event_name, gtag_obj);

      if (is_incognito() || typeof ga !== 'function') {
        throw 'Analytics disabled, skipping ga';
      }

      ga('set', 'page', event_obj.page_virtual);
      ga('send', 'pageview');
      ga('send', {
        hitType: 'event',
        eventCategory: event_obj.event_category,
        eventAction: event_obj.event_action,
        eventLabel: event_obj.event_label
      });
    } catch (err) {
      fg_console(err, gtag_obj);
    }
  }
}

function is_incognito() {
  return document.cookie.split(';').some(item => item.trim().startsWith('_fgl_incognito='));
}

export function is_production() {
  return !window.location.origin.match(/(wantgroup\.lo|maude|10\.0\.1)/);
}

export function is_localhost() {
  return window.location.origin.match(/(wantgroup\.lo|10\.0\.1)/);
}

export function get_current_user() {
  // This allows admin to sneak into party-orig
  if (admin_logged_in() && getQueryString('a_id')) {
    return getQueryString('a_id');
  }
  let token = get_cookie('_fgl_logged_in');
  return (token && token.split(':') && token.split(':')[0]) || $('#_selectedParty').attr('data-attendee-id') || false;
}

// Really only used for public, when there's multiple r_ids it's probably best not to
export function get_selected_r_id(node) {
  if (!admin_logged_in()) {
    return $('#_selectedParty').attr('data-rsvp-id');
  }
  // Try the open collapse
  var r_id = $('.card-body.collapse.show')
    .parents('.card')
    .attr('data-rsvp-id');
  return r_id || false;
}

export function get_selected_party() {
  return PartyStateInstance.get_selected_party();
}

// Should only be used when you specifically do not want the selected party ( ATM, launch )
export function get_current_party() {
  return PartyStateInstance.get_current_party();
}

export function set_current_party(p_id) {
  PartyStateInstance.set_current_party( p_id );
}

// Passing empty will clear the selected party
export function set_selected_party(p_id) {
  PartyStateInstance.set_selected_party(p_id);
}

export function clear_parties() {
  PartyStateInstance.clear();
}

export function fg_alert(msg, callback, more_buttons_obj, timer) {
  more_buttons_obj = more_buttons_obj || {};
  var offset = menuOffset(),
    offsetStyle = offset ? ' style="transform: translate(0, ' + offset + 'px);"' : '',
    close = '<button class="btn btn-secondary _modal-dismiss" data-dismiss="modal" aria-label="Close">Dismiss</button>',
    modal_html = `
          <div id="fg-modal-alert" class="modal fg-modal-sm fade" tabindex="-1">
              <div class="modal-dialog"${offsetStyle}>
                  <div class="modal-content" style="text-align: center">
                      <div class="modal-header">
                          <h6 style="padding: initial;" class="modal-title"><i class="fas fa-3x fa-exclamation-circle text-danger"></i></h6>
                      </div>
                      <div class="modal-body">
                          <div class="fg-modal-body">
                          </div>
                      </div> <!-- End Modal Body -->
                      <div class="modal-footer">
                      </div> <!-- End Modal Footer -->
                  </div> <!-- End Modal Content -->
              </div> <!-- End Modal Dialog -->
          <!-- End Modal -->
          </div>
          `,
    modal = $('#fg-modal-alert').length > 0 ? $('#fg-modal-alert') : $(modal_html);

  /*
   * Read the button obj, pass multiple ok var typical_arg = {
   * arbitrary_button_1: { label: "Save", classes: "class1 class2 ... ", },
   * arbitrary_button_2: { label: "Something Else", classes: "class1 class2
   * ... ", }, };
   */

  modal
    .find('.modal-footer')
    .find('button')
    .remove();

  $.each(more_buttons_obj, function(index, button_obj) {
    var button = $('<button>' + button_obj.label + '</button>');
    button.addClass(button_obj.classes);
    modal.find('.modal-footer').append(button);
  });

  // Add the close
  modal.find('.modal-footer').append(close);

  // Add modal to DOM
  $('#fg-delegate').append(modal);

  // Show it
  try {
    modal
      .find('.fg-modal-body')
      .html(msg)
      .end()
      .modal('show');
  } catch (e) {
    window.hardReload();
  }

  // Pass the modal back to the callback in case we have some trigger stuff we
  // want to implement
  if (callback && callback instanceof Function) {
    var has_more_buttons = Object.keys(more_buttons_obj).length > 0;
    if (!has_more_buttons) {
      // Default behavior, shows close and waits
      modal.on('hidden.bs.modal', function(e) {
        callback(modal);
      });
      if (timer) {
        setTimeout(function() {
          modal.modal('hide');
        }, timer);
      }
    } else {
      // Custom logic for more buttons, doesn't wait (yet) you have to
      // implement that
      callback(modal);
    }
  }
  return false;
}

/**
 *   This will notify admin if you pass an Error object to the first arg
 */
export function fg_error() {
  let args = [...arguments];
  var fg_debug = false,
    getStackTrace = function() {
      var stack = 'no-stack';
      if (args[0] instanceof Error) {
        stack = args[0].stack;
      }
      stack = stack.split('\n').map(function(line) {
        return line.trim();
      });
      return stack.splice(stack[0] == 'Error' ? 2 : 1);
    };

  try {
    fg_debug = document.cookie.split(';').filter(item => item.trim().startsWith('_fgl_debug=')).length;
  } catch (e) {}

  // Collect the args and add a_id
  var is_admin = admin_logged_in(),
    a_id = is_admin ? 'admin' : get_current_user() || 'public',
    data = {
      a_id: a_id,
      error: args.join('\n'),
      trace: getStackTrace().join('\n')
    };

  let valid_pages = $('body.party-orig').length;
  if (valid_pages && args[0] instanceof Error) {
    rest_entry_point('js-errors/?' + httpBuildQuery(data))
      .then(response => {
        fg_console('Logged js console error', response.response);
      })
      .catch(err => {
        fg_console(err);
      });
  }

  // Only log to actual console if cookie is present or not production
  if (!fg_debug && is_production()) {
    return;
  }
  console.error(args[0], args);
  console.trace();
}

/**
 * Get the query string value or the entire qs as an object if no key provided
 */
export function getQueryString(key) {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  if (key && Object.entries(params).length && params[key] ) {
    return params[key];
  }
  return null;
}

export function httpBuildQuery(pairsObj) {
  var qs = [];
  for (const [key, raw_value] of Object.entries(pairsObj)) {
    var encoded_value = encodeURIComponent(raw_value);
    qs.push(`${key}=${encoded_value}`);
  }
  return qs.join('&');
}

export function fg_console() {
  var MyDate = new Date();
  var MyDateString;
  MyDateString =
    '[' +
    ('0' + MyDate.getDate()).slice(-2) +
    '-' +
    (MyDate.toLocaleString('default', { month: 'short' }).toUpperCase() +
      '-' +
      MyDate.getFullYear() +
      ' ' +
      ('0' + MyDate.getHours()).slice(-2) +
      ':' +
      ('0' + MyDate.getMinutes()).slice(-2) +
      ':' +
      ('0' + MyDate.getSeconds()).slice(-2)) +
    ']';

  var log = console.log;

  // Only log if cookie is present or not production
  var fg_debug = false;
  try {
    fg_debug = document.cookie.split(';').filter(item => item.trim().startsWith('_fgl_debug=')).length;
  } catch (e) {}

  if (!fg_debug && is_production()) {
    return;
  }

  // 1. Convert args to a normal array
  var args = Array.from(arguments);
  // OR you can use: Array.prototype.slice.call( arguments );

  // 2. Prepend log prefix log string
  args.unshift(MyDateString + ':');

  // 3. Pass along arguments to console.log
  log.apply( console, args);
}

export function is_object(val) {
  if (val === null) {
    return false;
  }
  return typeof val === 'function' || typeof val === 'object';
}

/**
 * swap the menus
 * @param  string section_to_show Just pass in the value from data-toggle NOTE: it must be prefixed
 */
export function public_section_swap(section_to_show, last_scroll) {
  var sections_to_show = $('.' + section_to_show),
    sections_to_hide = $('[class*="_fg-public-section-"]').not(sections_to_show),
    last_scroll = last_scroll || 0,
    menu = sections_to_show.attr('data-menu'),
    menu_btn = $('.' + menu);

  // close all open modals
  if ($('.modal.show').length > 0) {
    $('.modal.show').modal('hide');
  }

  adjust_page_for_global_notice();

  sections_to_hide.addClass('d-none');
  sections_to_show.removeClass('d-none');
  bounceBack(last_scroll, 100);
}

export function attendee_master_section_swap(section_to_show, last_scroll, out_animation, in_animation) {
  var sections_to_hide = $("[class*='_fg-attendee-master-section-']").not($(section_to_show));

  out_animation = out_animation || 'fadeOut';
  last_scroll = last_scroll || 0;
  in_animation = in_animation || 'slideInRight';

  sections_to_hide
    .removeClass(in_animation)
    .addClass('animated ' + out_animation + ' faster')
    .addClass('d-none');
  section_to_show.removeClass('d-none ' + out_animation).addClass('animated ' + in_animation);
  $(window).scrollTop(last_scroll);
}

export function fade_other_inputs(current_flex, force_on) {
  force_on = force_on || false;
  var targets = $('._fg-editable').not($(current_flex)), // keep the current flex on, so only target others
    faded = $('body').hasClass('_faded-edit') || force_on;

  // Fade the others
  targets.toggleClass(Globals.STYLES.fadeAndBlur, !faded);

  // Activate the active editable
  $(current_flex).toggleClass(Globals.STYLES.fadeAndBlurActive, !force_on);

  // Return to default state when forced on
  if (force_on) {
    $(current_flex)
      .add(targets)
      .addClass(Globals.STYLES.editable);
  }

  $('body').toggleClass(`_faded-edit ${Globals.STYLES.fadedEdit}`, !faded);
}

export function are_you_sure(do_what, callback, callback_dismiss) {
  do_what = do_what || 'continue';
  fg_alert(
    'Are you sure you want to ' + do_what,
    function(modal) {
      modal.find('button').on('click', function(e) {
        var button = $(e.target);
        if (!button.is('._click-yes')) {
          if (button.is('._modal-dismiss') && callback_dismiss && callback_dismiss instanceof Function) {
            callback_dismiss(modal);
          }
          return;
        }
        // The guts of what to do when clicking the other buttons
        // goes here
        waiting(button);
        modal.modal('hide');
        if (callback && callback instanceof Function) {
          callback();
        }
      });
    },
    {
      sure: {
        label: 'YES',
        classes: 'btn btn-success _click-yes'
      }
    }
  );
}

export function waiting(elem) {
  const icon = `<i class="fas fa-circle-notch fa-spin _icon"></i>`;
  var width = $(elem)[0].clientWidth,
    save = $(elem).html();
  $(elem)
    .css('min-width', width + 'px')
    .attr('data-waiting', save)
    .html(icon);
}

export function done_waiting(elem, html) {
  var saved = html || $(elem).attr('data-waiting');
  $(elem)
    .attr('style', '')
    .attr('data-waiting', '')
    .html(saved);
}

export function sanitize_sms(sms) {
  var check_sms = sms.replace(/(^\+\d|[^0-9])/g, '');
  if (check_sms.length == 10) {
    return '+1' + check_sms;
  }
  return '';
}

export function valid_password(password) {
  const re_passes_twelve = /[A-Za-z0-9!@#$%^&*_-]{12,}/gm,
    re_passes_eight = /^(?=.*[\d])(?=.*[a-z]).{8,}$/gm,
    re_blacklist = /[\s+=;':"|\<>,.?}{\[\]]/gm,
    re_repeating_chars = /(.)\1{3,}$/gm;
  var valid =
    !re_blacklist.test(password) &&
    !re_repeating_chars.test(password) &&
    (re_passes_twelve.test(password) || re_passes_eight.test(password));
  return valid;
}

export function show_sms_modal(data) {
  var contact_id = data.contact_id || '',
    a_id = data.attendee_id,
    sms = data.sms || '',
    allow_sms = data.allow_sms == '1',
    stop_asking_sms = data.stop_asking_sms == '1';
  // Already opted in
  if ((allow_sms && sms != '') || stop_asking_sms) {
    if (data.redirect_link) {
      // window.location.replace(data.redirect_link);
      let nav = new Navigator(data.redirect_link, 'party-orig');
      nav.go();
    }
    return false;
  }
  $('._fg-sms')
    .attr('data-attendee-id', a_id)
    .attr('data-contact-id', contact_id)
    .val(sms)
    .trigger('keyup');

  $('#_fg-text-opt-in')
    .modal("dispose")
    .modal({backdrop: "static"})
    .on('hidden.bs.modal', function(e) {
      if (data.redirect_link) {
        // window.location.replace(data.redirect_link);
        let nav_after_hidden = new Navigator(data.redirect_link, 'party-orig');
        nav_after_hidden.go();
      }
      return true;
    });
  return true;
}

export function valid_email(email) {
  const re_email = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re_email.test(email);
}

// pass an array of (integer) reply's, example [ 3, 4 ] sums up fresh yes and
// maybe, this is _actual_ sum counting wpcf-reply-count
export function get_rsvp_count(reply_arr) {

  if ( ! Array.isArray(reply_arr) && Number.isInteger(reply_arr) ) {
    reply_arr = [reply_arr];
  }

  reply_arr = reply_arr || [Globals.REPLY.fresh_yes.int, Globals.REPLY.firm_yes.int, Globals.REPLY.maybe.int];

  const p_id = get_selected_party(),
        sums = Globals.PUBLIC_RSVP_SUMS[p_id] || false;

  if ( false === sums ) {
      fg_error( "Invalid sums object", Globals.PUBLIC_RSVP_SUMS );
      return 0;
  }

  let sum_count = 0;

  reply_arr.forEach(reply => {
    sum_count += (sums[reply] || 0 );
  });

  return sum_count;
}

export function blur_exceptions() {
  $.each($('._icon-overlay'), function(idx, item) {
    $(item)
      .parent()
      .find('img')
      .addClass('blur');
  });
}

export function get_pic_count(filter_config) {
  const s = Globals.IMAGE_STATUS;
  filter_config = filter_config || {
    status: [ s.approved.int ],  // Include approved only
    disallowed_faces: false           // Include faces that have been disallowed
  };

  let { status, disallowed_faces } = filter_config;

  let count = $("._image-management-data").get().filter( img => {
    img = $(img);
    let status_ok = status.includes( img.data("image-status") ),
        face_showing = img.data("face-showing"),
        allow_face = img.data("allow-face"),
        face_disallowed = face_showing === true && allow_face === false;

    if ( disallowed_faces === false && face_disallowed === true ) {
      return false;
    }

    return status_ok;
  }).length;
  fg_console( "Pic COUNT", count );
  return count;
}

export function is_waitlist_in_effect() {
  const yes_count = get_rsvp_count(Globals.REPLY.fresh_yes.int),
    waitlist_lock = get_party_meta(get_selected_party(), "waitlist_lock"),
    capacity = get_capacity(),
    in_effect = waitlist_lock == 1 || yes_count >= capacity;

   return in_effect;
}

export function get_capacity() {
  const party = get_selected_party();
  return get_party_meta(party, 'capacity') || 95;
}

export function get_cookie(name) {
  try {
    return decodeURIComponent(
      document.cookie
        .split(';')
        .find(row => row.trim().startsWith(name + '='))
        .split('=')[1]
    );
  } catch (e) {
    return null;
  }
}

// No expiry will remove cookie
// WARNING - THIS SHOULD ONLY BE USED TO CHECK THAT COOKIES ARE ENABLED
//           Do not set a cookie from both the server and the client
export function set_cookie(name, value, seconds_past_now, samesite) {
  value = value || '0';
  samesite = samesite ? '; SameSite=' + samesite : '';

  const d = new Date();

  d.setTime(seconds_past_now ? d.getTime() + seconds_past_now * 1000 : 1);
  let expiry = d.toUTCString(),
    secure = window.location.protocol == 'https:' ? '; Secure' : '';

  let cookie_value = `${name}=${value}; path=/; expires=${expiry}${samesite}${secure}`;
  document.cookie = cookie_value;
}

export function cookies_enabled() {
  if (navigator.cookieEnabled) return true;

  // set and read cookie
  set_cookie('_fgl_check_cookie', 1, 60 * 5);
  var ret = document.cookie.indexOf('_fgl_check_cookie=') != -1;

  // delete cookie
  set_cookie('_fgl_check_cookie');

  $('._cookie-check').toggleClass('d-none', ret);
  $('._fg-access input,._fg-verify-email-btn,.loginComponent input')
    .toggleClass('disabled', !ret)
    .prop('disabled', !ret)
    .css('cursor', ret ? 'inherit' : 'not-allowed');

  $('._fg-access,.loginComponent').css('border', ret ? 'inherit' : '3px dashed var(--danger)');
}

export function progressIcon(command, pad) {
  pad = pad === false ? '' : 'ml-3';
  var classes = ['icon', pad, '_delete-progress'];
  $('._delete-progress').remove();
  if (!command) return;
  switch (command) {
    case 'progress':
      classes.push('text-success', 'fas', 'fa-circle-notch', 'fa-spin');
      break;
    case 'error':
      classes.push('text-danger', 'fas', 'fa-exclamation-circle');
      break;
    case 'success':
      classes.push('text-success', 'fas', 'fa-thumbs-up');
      break;
    default:
  }
  return $('<i class="' + classes.join(' ') + '"></i>');
}

export function handle_user_input_groups(on_off) {
  if (on_off == 'off') {
    $('.wpt-form-set-radios input,._wpt-form-set-checkboxes-wpcf-position input').attr('disabled', true);
    return;
  }
  // $('#fg-delegate').on('click', '._fg-edit-inline, e => handler_dispatch(e, 'handle_inline_click'));
  $('.wpt-form-set-radios input,._wpt-form-set-checkboxes-wpcf-position input').removeAttr('disabled');
}

export function get_parties_attended(a_id, p_id) {
  const evaluation = data => {
    if (!p_id) {
      return data.filter(elem => elem['a_id'] == a_id);
    }
    return data.find(elem => {
      return elem['a_id'] == a_id && elem['p_id'] == p_id;
    });
  };
  return evaluation(ajax_obj.parties_attended);
}

export function font_spy() {
  require('jquery-fontspy/jQuery-FontSpy.js');
  fontSpy('Jost,Assistant', {
    success: function() {
      $('#fg-delegate').attr('style', '');
    },
    failure: function() {
      $('#fg-delegate').attr('style', '');
    }
  });
}

export function bounceBack(where, timing) {
  var timing = timing || 500;
  if ($('#_selectedParty._emergency-alert').length || $('#_selectedParty._first-class').length) {
    where -= $('._global-notice').get(0).offsetHeight;
  }
  return scrollTo(where, timing).catch(error => {
    fg_error(error);
  });
}

// Returns object of useful data
export function parseUserAgent( ua_string ) {
  var parser = require('ua-parser-js');
  return parser(ua_string);
}

export function is_mobile() {
  var check = false;
  (function(a, b) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
}

export function observeRendering(wrapper, callback, config) {
  // Select the node that will be observed for mutations
  const targetNode = $(wrapper).get(0);

  // Options for the observer (which mutations to observe)
  config = config || { attributes: true, childList: true, subtree: true };

  // Create an observer instance linked to the callback function
  const observer = new MutationObserver(callback);

  // Start observing the target node for configured mutations
  observer.observe(targetNode, config);
}

export function observeIntersection(observedElements, handleIntersect, options) {
  let observer;

  options = options || {
    root: null,
    rootMargin: '0px',
    threshold: 1.0
  };

  observer = new IntersectionObserver(handleIntersect, options);
  observedElements.forEach(el => {
    observer.observe(el);
  });
}

export function hardReload( urlOverride ) {
  var url = urlOverride || window.location.href;
  var form = `
    <form action="${url}" method="POST" id="_hardReload"></form>
  `;
  if (!$('#_hardReload').length) {
    $('body').append(form);
  }
  $('#_hardReload').submit();
}

export function clearTimers() {
  var clearedTimers = [];
  const prom = (resolve, reject) => {
    while (Globals.TIMERS.length) {
      let timer = Globals.TIMERS.pop();
      window.clearTimeout(timer);
      clearedTimers.push(timer);
    }
    let cleared = clearedTimers.join(', ');
    fg_console(`Cleared timers: ${cleared}`);
    resolve(clearedTimers);
  };
  return new Promise(prom);
}

// This is a callback that transforms the data in the RenderLazy in load_analytics and load history in atm
export function sort_analytics(response) {
  // Sort the a_ids so the lastest floats to the top
  let unsorted_a_ids = Object.keys(response),
    myMap = new Map();

  unsorted_a_ids.forEach((a_id, i) => {
    myMap.set(a_id, response[a_id][0]['unix_timestamp']);
  });
  const sorted_map = new Map([...myMap.entries()].sort((a, b) => b[1] - a[1])),
    sorted_a_ids = Array.from(sorted_map.keys());

  return { sort_order: sorted_a_ids, analytics_data: response };
}
